import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { isLength, isMatch, hasDigit, hasSpecialCharacter, hasUppercase } from "../../components/helper/validate";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import Spinner from '../../components/Spinner/Spinner';
import "react-toastify/dist/ReactToastify.css";
import API_BASE_URL from "../../apiConfig"; 



import {
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  CardSubtitle,
  Col,
  Row

} from "reactstrap";

// import formatDistanceToNow from 'date-fns/formatDistanceToNow'

const initialState = {
  first_name: "",
  last_name: "",
  password: "",
  cf_password: "",
};

const initialTimezone = 'No time zone identified.'

const Account = () => {
  const [timeZone, setTimeZone] = useState(null);
  // const inputFile = useRef(null);
  // const [visible, setVisible] = useState(false);
  const { user, token, dispatch } = useContext(AuthContext);
  const [avatar, setAvatar] = useState(false);
  const [data, setData] = useState(initialState);
  const { first_name, last_name, phone_number, password, cf_password } = data;
  const navigate = useNavigate();


  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    
  };

  //Chnage phone number
  const handlePhoneChange = (value) => {
    setData({ ...data, phone_number: value });
  };


  //Get timezone

  useEffect(() => {
    const fetchTimeZone = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/auth/probinds`, {
          headers: {
            'Authorization': token
          }
        })
        setTimeZone(response.data[0].time_zone || initialTimezone);
      } catch (error) {
        setTimeZone(initialTimezone);
        console.error(error);
      }
    };
    fetchTimeZone();
  }, [token]);

  //Deactivate account
  const handleDeactivate = () => {
    navigate('/support');
  };

  // const handleInput = () => {
  //   inputFile.current.click();
  // };

  // const handleClick = () => {
  //   setVisible(!visible);
  // };


  // const changeAvatar = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // get file
  //     const file = e.target.files[0];
  //     let formData = new FormData();
  //     formData.append("avatar", file);

  //     // upload to cloudinary
  //     const res = await axios.post("/api/upload", formData, {
  //       headers: {
  //         "content-type": "multipart/form-data",
  //         Authorization: token,
  //       },
  //       onUploadProgress: (x) => {
  //         if (x.total < 1024000)
  //           return toast("Uploading", {
  //             className: "bg-upload",
  //             bodyClassName: "bg-upload",
  //             autoClose: 7000,
  //           });
  //       },
  //     });
  //     setAvatar(res.data.url);
  //   } catch (err) {
  //     toast(err.response.data.msg, {
  //       className: "toast-failed",
  //       bodyClassName: "toast-failed",
  //     });
  //   }
  // };

  const updateInfo = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.patch(
        `${API_BASE_URL}/api/auth/user_update`,
        {
          first_name: first_name ? first_name : user.first_name,
          last_name: last_name ? last_name : user.last_name,
          avatar: avatar ? avatar : user.avatar,
          phone_number: phone_number ? phone_number : user.phone_number
        },
        {
          headers: { Authorization: token },
        }
      );
    
      const updatedUser = await axios.get(`${API_BASE_URL}/api/auth/user`, {
        headers: { Authorization: token },
      });
      dispatch({ type: "GET_USER", payload: updatedUser.data });
      return toast(res.data.msg, {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
    } catch (err) {
      toast(err.response.data.msg, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };


  const updatePassword = async (e) => {
    e.preventDefault();
    // check password length
    if (isLength(password))
      return toast("Password must be at least 12 characters.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check match
    if (!isMatch(password, cf_password))
      return toast("Passwords do not match.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check password for digits
    if (!hasDigit(password))
      return toast("Please include a digit in the password (0 - 9).", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check password for special characters
    if (hasSpecialCharacter(password))
      return toast("Please include a special character in the password (! @ # $ % ^ & *).", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check password for uppercase letter
    if (hasUppercase(password))
      return toast("Please include an upper case letter in your password.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });

    try {
      const res = await axios.post(
        "/api/auth/reset_pass",
        { password },
        {
          headers: { Authorization: token },
        }
      );
      return toast(res.data.msg, {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
    } catch (err) {
      return toast(err.response.data.msg, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, password: "", cf_password: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (last_name || avatar) {
    //   updateInfo();
    // }
    if (password) {
      updatePassword();
      handleReset();
    }
  };




  return (
    <>
      <ToastContainer />
      <h3>Account Overview</h3>
      <Row>
        <Col md="6">
          <Card style={{ height: '400px' }}>
            <CardBody className="custom-card-body">
              <h4>User Profile</h4>
              <form onSubmit={updateInfo}>
                <FormGroup>
                  <hr />
                  <Label for="email">Email/Username :</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder={user.email}
                    disabled={true}
                  >
                  </Input>
                  <Label for="firstname">First Name</Label>
                  <Input
                    id="firstname"
                    name="first_name"
                    placeholder={user.first_name}
                    type="first_name"
                    onChange={handleChange}
                  />
                  <Label for="lastname">Last Name</Label>
                  <Input
                    id="lastname"
                    name="last_name"
                    placeholder={user.last_name}
                    type="last_name"
                    onChange={handleChange}
                  />


                </FormGroup>
                <br />
                <div className="d-flex justify-content-end">
                  <Button className="btn" outline color="primary">
                    Update Profile
                  </Button>
                </div>
              </form>

            </CardBody>
          </Card>
        </Col>

        <Col md="6">
          <Card style={{ height: '200px' }}>
            <CardBody className="custom-card-body">
              <h4>Account Phone Number</h4>
              <hr />

              <form onSubmit={updateInfo}>
                <FormGroup>
                  {/* <Input
                      id="phonenumber"
                      name="email"
                      placeholder={user.phone_number}
                      type="tel"
                    /> */}
                  <PhoneInput
                    type="tel"
                    placeholder={user.phone_number}
                    name="phone_number"
                    value={data.phone_number}
                    defaultCountry="US"
                    onChange={handlePhoneChange}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button className="btn" outline color="primary">
                    Update Phone Number
                  </Button>
                </div>

              </form>
            </CardBody>
          </Card>

          <Card style={{ height: '170px' }}>
            <CardBody className="custom-card-body">
              <h4>Timezone</h4>
              <Form>
                <FormGroup>
                  {
                    timeZone ? (
                      <Input
                        id="exampleSelect"
                        name="timezone"
                        type="timezone"
                        placeholder={timeZone}
                        disabled={true}
                      />
                    ) : (
                      <Spinner />
                    )
                  }
                  <br />
                  <h6 style={{ fontStyle: 'italic' }}>* The time zone is automatically set when your business listing becomes active.</h6>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>

      </Row>
      <Row>
        <Col xs="6">
          <Card style={{ height: '275px' }}>
            <CardBody className="custom-card-body">
              <h4>Change Password</h4>
              <form onSubmit={updatePassword}>
                <FormGroup>
                  {/* <Label for="currentPassword">Current Password</Label>
                <Input
                  id="examplePassword"
                  name="password"
                  placeholder="Type Current Password"
                  type="password"
                /> */}
                  <Label for="password">New Password</Label>
                  <Input
                    id="newPassword"
                    name="password"
                    placeholder="Type New Password"
                    type="password"
                    onChange={handleChange}
                  />
                  <Label for="verifynewPassword">Confirm New Password</Label>
                  <Input
                    id="verifynewPassword"
                    name="cf_password"
                    placeholder="Re-Type New Password"
                    type="password"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button>Change Password</Button>
                </div>

              </form>
            </CardBody>
          </Card>
        </Col>
        <Col xs="6">
          <Card style={{ height: '275px' }}>
            <CardBody className="custom-card-body">
              <h4>Deactivate Account</h4>
              <hr />
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                For security purposes, account deactivations can only be performed by our administrators upon your request. Please click the "Begin Deactivation" button below in order to instruct our administrators to deactivate your account.
              </CardSubtitle>
              <br />
              <div className="d-flex justify-content-end">
                <Button className="btn" outline color="primary" onClick={handleDeactivate}>
                  Begin Deactivation
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Account