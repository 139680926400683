import {
    Card,
    CardBody,

  } from "reactstrap";

const ChangeAvailability = () => {
    return (  
        <>
        <Card className="custom-card-body">
        <CardBody>
        <h1>Changing your availability.</h1>
        <hr/>
        <p>
        Your availability plays a crucial role in connecting with potential customers on HelperFox. We understand that your schedule may change, and that's why we provide you with the flexibility to customize your availability hours as needed. Follow these simple steps to adjust your business listing's availability and ensure that you're reachable when it matters most.
      </p>
      <h3>Step 1: Access Your Business Listing</h3>
      <p>
        Once your business listing is live on HelperFox, you can make changes to your availability. Log in to your account and navigate to the business listing you wish to modify.
      </p>
      <h3>Step 2: Edit Your Availability</h3>
      <p>
        Scroll down to the Availability section of your business listing. This section will only be visible after your business listing is created. Under the Availability section, you'll find the "Edit Your Availability" button – click on it to proceed.
      </p>
      <h3>Step 3: Customize Your Hours</h3>
      <p>
        You'll be presented with a user-friendly interface where you can customize your availability hours. Update your hours for each day of the week according to your current schedule. This allows you to reflect your true availability, ensuring that you're reachable when potential customers are seeking your services.
      </p>
      <h3>Step 4: Save Changes</h3>
      <p>
        After you've adjusted your availability hours, don't forget to save your changes. Review your modifications to ensure accuracy, then click the "Save Changes" button. Your updated availability will now be reflected on your business listing.
      </p>
      <h3>Step 5: Stay Up to Date</h3>
      <p>
        As your schedule evolves, feel free to return to the Availability section whenever you need to make further adjustments. We recommend keeping your availability up to date to ensure that you're connecting with potential customers during the times that suit you best.
      </p>
      <p>
        <strong>Conclusion:</strong>
        Your availability is a vital aspect of your business listing on HelperFox. By following these simple steps, you can easily customize your availability hours to match your schedule. This flexibility ensures that you're maximizing your opportunities to connect with customers who are actively seeking your expertise.
      </p>
        
        </CardBody>
        </Card>
        </>
    );
}
 
export default ChangeAvailability;