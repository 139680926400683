import { useCallback, useContext, useState } from "react";

import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { isEmpty, isEmail } from "../helper/validate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../Input/Input";
import "./login.css";
import { AuthContext } from "../../context/AuthContext";
import API_BASE_URL from "../../apiConfig"; 
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody
} from "reactstrap";


const initialState = {
  name: "",
  password: "",
};

const Login = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const [token, setToken] = useState(null);
  const { email, password } = data;
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();




  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  const login = async (e) => {
    e.preventDefault(); 
    // check fields
    
      if (isEmpty(email) || isEmpty(password))
        return toast("Please fill in all fields.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check email
      if (!isEmail(email))
        return toast("Please enter a valid email address.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      try {
        const res = await axios.post(`${API_BASE_URL}/api/auth/signing`, { email: email.toLowerCase(), password });
        if (res.status == 200) {
          const phoneNumber = res.data.phone
          
          const token = res.data.token; 
      

          dispatch({ type: "AUTHENTICATION_VERIFIED" });
          navigate('/auth/verify', { state: { phoneNumber, token: token } });
          try {
            await axios.post(`${API_BASE_URL}/api/auth/verify`, { phoneNumber });
          } catch (error) {
            console.error(error);
          }
        }
      } catch (err) {
        toast("Email not verified. Please check your emails for a verification link after creating an account.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      }
    // }
  };



  return (
    <>
      <ToastContainer />
      
      <div className="authInputArea">
       <h1>Log into your business account</h1> 
      <Card>
        <CardBody className="custom-card-body">
          <form onSubmit={login}>
            <Input
              type="email"
              text="Email"
              name="email"
              handleChange={handleChange}
            />
            <Input
              name="password"
              type={visible ? "text" : "password"}
              icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
              text="Password"
              handleClick={handleClick}
              handleChange={handleChange}
            />
            <div className="login_btn">
              <button type="submit">login</button>
            </div>
          </form>
          </CardBody>
        </Card>
      </div>
     
    </>
  );
};

export default Login;
