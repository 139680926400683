import {useAuthContext} from '../../hooks/useAuthContext'
import axios from "axios";
import { useEffect, useState } from "react";
import API_BASE_URL from "../../apiConfig"; 
import {
    Card,
    CardBody,
    Table

  } from "reactstrap";


const History = () => {
  const [invoices, setInvoices] = useState([]);
  const {user} = useAuthContext()

  const stripeCustomerId =  user.stripeCustomerId;

  useEffect(() => {
    const fetchInvoices = async () => {
      
      try {
        
        const response = await axios.get(`${API_BASE_URL}/api/auth/invoices?stripeCustomerId=${stripeCustomerId}` );
        const invoiceData = response.data.data;
        
  
      
          setInvoices(invoiceData);
          
       
      } catch (error) {
        console.log(error);
      }
    };

    fetchInvoices();
  }, [stripeCustomerId]);



    return ( 
    <Card style={{ height: '300px' }}>
      <CardBody className="custom-card-body">
      <h4>Payment History</h4>
      <hr/>
      <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
                    <tr key={invoice.id}>
                      <td>{new Date(invoice.created * 1000).toLocaleString()}</td>
                      <td>{(invoice.amount_paid/ 100).toFixed(2)} {(invoice.currency).toUpperCase()}</td>
                      <td>{invoice.status}</td>
                      <td> <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">View</a></td>
                    </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
        
     );
}
 
export default History;