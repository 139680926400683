import {
    Card,
    CardBody,

  } from "reactstrap";

const AddListing = () => {
    return (  
        <>
        <Card>
        <CardBody>
        <h1>How to add a business listing?</h1>
        <hr/>
      <p>
        Adding your business listing to HelperFox is a straightforward process that helps you connect with potential customers. Follow these simple steps to showcase your services and expand your reach.
      </p>
      <h3>Step 1: Upgrade to Standard Plan</h3>
      <p>
        Before you can add a business listing, ensure that you have upgraded to our Standard Plan. This plan provides you with the capability to showcase your services and connect with customers who are seeking your expertise.
      </p>
      <h3>Step 2: Access Your Business Listing</h3>
      <p>
        Once your Standard Plan is active, log in to your HelperFox account. You will now have access to the feature to add your business listing.
      </p>
      <h3>Step 3: Complete the Business Listing Form</h3>
      <p>
        Fill out the business listing form with the following details:
      </p>
      <ul>
        <li><strong>Business Name:</strong> Enter the name of your business exactly as you want it to appear in your listing.</li>
        <li><strong>Phone Number:</strong> Provide a contact number where customers can reach you directly.</li>
        <li><strong>Your Name:</strong> Add your name, ensuring a personal touch in your listing.</li>
        <li><strong>Zip Code:</strong> Enter the zip code of your primary location.</li>
        <li><strong>Travel Distance:</strong> Specify the maximum distance (in miles) you are willing to travel for a job. This ensures that you connect with customers within your preferred service area.</li>
      </ul>
      <h3>Step 4: Showcase Your Services</h3>
      <p>
        Take advantage of the provided space to highlight the services you offer, your expertise, and any specializations that set you apart. A well-crafted description will attract potential customers and give them a clear understanding of what you bring to the table.
      </p>
      <h3>Step 5: Submit and Connect</h3>
      <p>
        Review your listing for accuracy and completeness, then submit it. Once approved, your business listing will be visible to users seeking your services, helping you connect and grow your clientele.
      </p>
      <p>
        <strong>Conclusion:</strong>
        Adding a business listing to HelperFox is a simple yet effective way to reach a wider audience and showcase your services. By following these easy steps, you'll position yourself to connect with customers who are actively seeking the expertise you provide.
      </p>
        </CardBody>
        </Card>
        </>
    );
}
 
export default AddListing;