
// import { BiBookContent } from "react-icons/bi";
// import { BiUserCircle } from "react-icons/bi";
// import { BiLogOut } from "react-icons/bi";
import { Button, Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
// import { useAuthContext } from '../hooks/useAuthContext'
// import "./sidebar.css";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";



const navigation = [
  {
    title: "Dashboard",
    href: "/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: "My Account",
    href: "/account",
    icon: "bi bi-person",
  },
  {
    title: "Billing",
    href: "/billing",
    icon: "bi bi-layout-split",
  },
  {
    title: "Knowledge Base",
    href: "/knowledgebase",
    icon: "bi bi-book",
  },
  {
    title: "Support",
    href: "/support",
    icon: "bi bi-question",
  }
];


const Sidebar = () => {
  const { dispatch } = useContext(AuthContext);

  const toggleSidebar = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  let location = useLocation();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.get("/api/auth/signout");
      localStorage.removeItem("_appSignging");
      localStorage.removeItem("_apprftoken");
      dispatch({ type: "SIGNOUT" });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-dark">
      <div className="d-flex">
        {/* Close button for mobile */}
        <Button
          color="white"
          className="ms-auto text-white d-lg-none toggle-button"
          onClick={toggleSidebar} // Use toggleSidebar function here
        >
          Close
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <Link
                to={navi.href}
                className={
                  location.pathname === navi.href
                    ? "active nav-link py-3"
                    : "nav-link py-3"
                }
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
              </Link>
            </NavItem>
          ))}
          <hr style={{ color: 'grey'}}/>
            <Button 
                  onClick={handleLogout}>
                  Log out
            </Button>
        </Nav>
      </div>
    </div>
  );
};
export default Sidebar;
