import {
    Card,
    CardBody,
    FormGroup,
    Input,
    Button
  } from "reactstrap";

const Discounts = () => {
  return ( 
    <Card style={{ height: '300px' }}>
    <CardBody className="custom-card-body">
    <h4>Discounts & Coupons</h4>
    <hr/>
    <div>Please submit your discount or coupon code that you have been provide below.</div>
    <form>
        <FormGroup>
              <Input
                id="couponcode"
                name="coupon_code"
                placeholder="Coupon Code"
                type="coupon_code"
              />    
        </FormGroup>
        <br/>
        <div className="d-flex justify-content-end">
                  <Button className="btn" outline color="primary">
                  Submit 
                  </Button>
              </div>   
    </form>
  
    </CardBody>
  </Card>
      
   );
}

export default Discounts;