import { Col, Row } from "reactstrap";
import ProbindForm from "../components/Probind/ProbindForm"
import ProbindDetails from "../components/Probind/ProbindDetails"
import ZipCodes from "../components/Probind/ZipCodes"
import Availability from "../components/Probind/Availability"
import CoverageMap from '../components/Probind/CoverageMap';
import axios from "axios";
import { useEffect, useState } from "react";
import { useProbindsContext } from "../hooks/useProbindsContext"
import { useAuthContext } from '../hooks/useAuthContext'
import { Alert } from "reactstrap";
import PayModal from "../components//Modals/PayModal";
import API_BASE_URL from "../apiConfig"; 


const Starter = () => {
  const { probinds, fetchProbinds, dispatch } = useProbindsContext()
  const { user, token } = useAuthContext()
  const [error, setError] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);



  const handleLocationChange = (longitude, latitude) => {
    setLongitude(longitude);
    setLatitude(latitude);
  };


  useEffect(() => {
    const fetchProbinds = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}/api/auth/probinds`, {
          headers: {
            'Authorization': token
          }
        })

        if (res.status === 200) {
          dispatch({ type: 'SET_PROBINDS', payload: res.data })

        }
      } catch (error) {
        setError(error)
      }



    }

    if (user) {
      fetchProbinds()
    }

  }, [dispatch, fetchProbinds, user, token])

  return (
    <div>

      {probinds && probinds.length !== 0 ? (
        <Row>
          <Col md="6">
            {probinds.map(probind => (
              <ProbindDetails onLocationChange={handleLocationChange} key={probind._id} probind={probind} />
            ))}
          </Col>
          <Col md="6">
            <Availability />
            <ZipCodes onLocationChange={handleLocationChange} />
          </Col>
          <CoverageMap zipCode={probinds[0].zipCode} radius={probinds[0].distance} longitude={longitude} latitude={latitude} />
        </Row>
      ) : (
        <Row>
          <Alert color="danger">
            <h4 className="alert-heading">No business listings are live!</h4>
            {user.pay_status === 'paid' ? (
              <span>
                Please create a new business listing by filling out the information below.
              </span>
            ) : (
              <span>
                Please upgrade your account and add payment before creating a new business listing below.
                <PayModal />
              </span>
            )}
          </Alert>


          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8,
            }}
          >
            <ProbindForm />
          </Col>
        </Row>)
      }
    </div>
  );
};

export default Starter;