import {useAuthContext} from '../../hooks/useAuthContext'
import { useProbindsContext } from "../../hooks/useProbindsContext"
import axios from "axios";
import { useEffect, useState } from "react";
import API_BASE_URL from "../../apiConfig"; 
import {
    Card,
    CardBody,
    Table,
    Button
  } from "reactstrap";

const ZipCodes = ({ onLocationChange }) => {
    const {probinds} = useProbindsContext()
    const [zipListings, setZipListings] = useState([]);
    const [deletedZipCodes, setDeletedZipCodes] = useState([]);
    const {user} = useAuthContext()

    const userId = user._id


    useEffect(() => {
      const fetchZipListings = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/auth/ziplist/${userId}`);
          const data = response.data[0].dataList;
          const longitude = data[0].longitude;
          const latitude = data[0].latitude;
         
          setZipListings(data);
          onLocationChange(longitude, latitude);
          
        } catch (error) {
          console.error('Error fetching zip listings:', error);
        }
      };
  
      fetchZipListings();
    }, [userId, probinds, onLocationChange]);

    const handleDelete = (id) => {
      setZipListings((prevListings) =>
        prevListings.filter((zipListing) => zipListing._id !== id)
          );
          setDeletedZipCodes((prevDeletedCodes) => [...prevDeletedCodes, id]);
        };
      
        const handleUpdate = async () => {
          try {
            await axios.delete(`${API_BASE_URL}/api/auth/manycodes`, {
              data: { userId, zipCodes: deletedZipCodes },
            });
            
          } catch (error) {
            console.error('Error deleting zip codes:', error);
          }
        };
    
    return ( 
      <>
        <Card>
            <CardBody className="custom-card-body" style={{ height: '566px' }}>
                <h3>Area Covered</h3>
                <hr />
                    <div style={{ maxHeight: '70%', overflowY: 'scroll' }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Zip Code</th>
                                    <th>City/Town</th>
                                    <th>State</th>
                                    <th>Distance</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {zipListings.map((zipListing) => (
                                <tr key={zipListing._id}>
                                    <td>{zipListing.zipCode}</td>
                                    <td>{zipListing.city}</td>
                                    <td>{zipListing.state}</td>
                                    <td>{zipListing.distance}</td>
                                    <td>
                                      <span style={{ color: 'red', cursor: 'pointer' }} 
                                      onClick={() => handleDelete(zipListing._id)}>x</span>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div> 
          <br /> 
               <div className="d-flex justify-content-end">
                    <Button className="btn" outline color="info" onClick={handleUpdate}>
                        Save Changes
                        </Button>  </div> 
                     
            </CardBody>

        </Card>
     </>
     );
     
}
 
export default ZipCodes;