import { useState, useContext, useCallback } from "react";
// import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from "axios";
import Input from "../Input/Input";
import { isEmpty } from "../helper/validate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import { Link } from 'react-router-dom';
import NavLogo from './helperfox-logo.png'
import API_BASE_URL from "../../apiConfig"; 
import {
  Card,
  CardBody,
  Label
} from "reactstrap";


const TwoFA = () => {
  const location = useLocation();
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [pincode, setPincode] = useState("");
  

  const phoneNumber = location.state?.phoneNumber;
  const token = location.state?.token;
  const maskedPhoneNumber = phoneNumber.substr(0, 2) + "-XXX-XXX-XX" + phoneNumber.substr(10);

  // const handleRecaptchaVerify = useCallback((token) => {
  //   setToken(token)
  // }, [])

  const handleChange = (e) => {
    setPincode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (token) {
      const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
          (input) => (input.value = "")
        );
        setPincode({ pincode: "" });
      };

      // check pincode field
      if (isEmpty(pincode))
        return toast("Please enter pincode.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      try {
        
        const res = await axios.post(`${API_BASE_URL}/api/auth/check-code`, { pincode, phoneNumber });
        handleReset();
        if (res.data.errorCode == 0) {
         
          
          localStorage.setItem("_appSignging", true);
          localStorage.setItem("_apprftoken", token);
          // dispatch({ type: "SET_TOKEN", payload: token });
          // introduce a new action type that validates whether the username and password is correct
          dispatch({ type: "SIGNING" });
          navigate('/');
          return toast("Pincode confirmed", {
            className: "toast-success",
            bodyClassName: "toast-success",
          });

        } else {
          return toast("Incorrect or invalid pincode", {
            className: "toast-success",
            bodyClassName: "toast-success",
          });
        }
      } catch (err) {
        toast(err.response.data.msg, {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      }
    // }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
    
      const res = await axios.post(`${API_BASE_URL}/api/auth/resend-code`, { phoneNumber });
 
      return toast(res.data.msg, {
        className: "toast-success",
        bodyClassName: "toast-success",
      });

    } catch (err) {
      toast(err.response.data.msg, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="authlayout_navbar">
        <div className="authlayout_logo">
          <img
            src={NavLogo}
            alt="logo" />
        </div>
        <div>
          <a href="https://helperfox.com">Back to Main Site</a>
        </div>
      </div>
      <div className="authInputArea">
        {/* <img src="./assets/img/shuttle.svg" alt="logo" /> */}

        <Card>
          <CardBody className="custom-card-body">
            <h3>Enter your 2FA code</h3>
            <div>
              A time-sensitive code was sent to phone <br />number {maskedPhoneNumber}
            </div>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                text="pincode"
                name="pincode"
                handleChange={handleChange}
              />
              <a href="#" onClick={handleResend}>Resend Code</a>
              <div className="login_btn">
                <button type="submit">submit</button>
              </div>
              <Link to="/">Go Back</Link>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="custom-card-body">
            <Label>What is this?</Label>
            <div>
              Two-factor authentication (2FA) is an additional security measure that enhances the protection of your account. This extra layer of security ensures
              that even if one of the authentication factors is compromised, the risk of your account being access by unauthorized parties is significantly reduced.
            </div>
          </CardBody>
        </Card>
      </div>
      {/* <GoogleReCaptcha onVerify={handleRecaptchaVerify} /> */}
    </>
  );
}

export default TwoFA;