import { useNavigate } from 'react-router-dom';
import Method from "../../components/Billing/Method";
import History from "../../components/Billing/History";
import Discounts from "../../components/Billing/Discounts";
import Subscription from "../../components/Billing/Subscription";
import {
  Card,
  CardBody,
  Button,
  Col,
  Row
} from "reactstrap";


const Billing = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/support');
  };

  return (
    <>
    <h3>Billing Overview</h3>

    <Row>
      <Col xs="6">
        <Subscription /> 
      </Col>
      
      <Col xs="6">
        <Method />
      </Col>
    </Row>  
    
    <Row>
    <Col xs="6">
      <History />
    </Col>

    <Col xs="6">
      <Discounts />
    </Col>
    </Row>   
      <Card>
          <CardBody className="custom-card-body">
          <h4>Help & Support</h4>
          <hr />
          {/* Quick way to reach out about support related matters.  */}
          <div>
            Do you have a billing related question?
          </div>
          <br />
             <Button onClick={handleClick}>
              Contact Us
              </Button>
          </CardBody>
      </Card>
    </>
  )
}

export default Billing