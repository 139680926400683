import { ProbindsContext } from "../context/ProbindContext";

import { useContext } from "react";

export const useProbindsContext = () => {
    const context = useContext(ProbindsContext)

    if (!context) {
        throw Error('useProbindsContext must be used inside a ProbindsContextProvider')
    }

    return context
}