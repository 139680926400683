import React from 'react';
import { Button, Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter,  } from 'reactstrap';


const Modal = ({ open, title, onClose, children, size, footer }) => {
    return (
        <BootstrapModal  size={size} isOpen={open} toggle={onClose}>
            <ModalHeader toggle={onClose}>
                <h4 className='mb-0'>{title}</h4>
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {
                    footer || (
                        <Button variant="secondary" onClick={onClose}>
                            Close
                        </Button>
                    )
                }
            </ModalFooter>
        </BootstrapModal>
    )
}

export default Modal;