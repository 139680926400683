import { React, useState, useEffect } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
// import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext'
import Avatar from "../Avatar/Avatar";
import DefaultAvatar from "./blank_avatar.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import API_BASE_URL from "../../apiConfig"; 
import LogoSmall from "./LogoSmall";
import {
  Card,
  CardImg,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  // UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
  Offcanvas, 
  OffcanvasHeader, 
  OffcanvasBody, 
  Form, 
  FormGroup, 
  Label, 
  Input
} from "reactstrap";
import Logo from "./Logo";
// import user1 from "../../assets/images/users/user4.jpg";

const placeholderImage = require('./blank_avatar.png');

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, token, dispatch } = useAuthContext();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewSource, setPreviewSource] = useState();
  const [error, setError] = useState(null);
  const [avatar, setAvatar] = useState(false)

  const userId = user._id

  useEffect(() => {
    if (user.avatar) {
      setPreviewSource(user.avatar);
    } else {
      setPreviewSource(placeholderImage.default);
    }
  }, [user.avatar]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    previewFile(file);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;
  
    try {
      const formData = new FormData();
      formData.append('avatar', selectedFile);
  
      const response = await axios.post(`${API_BASE_URL}/api/auth/uploadPhoto`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      });
  
      // Handle successful upload response
  
      if (response.status === 200) {
        setShowOffcanvas(false);
        try {
          const updatedUser = await axios.patch(
            `${API_BASE_URL}/api/auth/updateurl/${userId}`,
            {
              avatar: response.data.url,
            },
            {
              headers: { Authorization: token },
            }
          );
     
          dispatch({ type: "GET_USER", payload: { avatar: response.data.url, first_name: user.first_name } });
          return toast(updatedUser.data.msg, {
            className: "toast-success",
            bodyClassName: "toast-success",
          });
        } catch (error) {
          toast(error.response.data.msg, {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
        }
      }
    } catch (error) {
      toast(getErrorMessage(error), {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };
  
  function getErrorMessage(error) {
    if (error.response && error.response.data && error.response.data.msg) {
      return error.response.data.msg;
    } else {
      return "An error occurred during the request.";
    }
  }

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const handleCloseCanvas = () => {
    setShowOffcanvas(false);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.get("/api/auth/signout");
      localStorage.removeItem("_appSignging");
      localStorage.removeItem("_apprftoken");
      dispatch({ type: "SIGNOUT" });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Navbar color="white" light expand="md" className="fix-header">
      <div className="d-flex align-items-center">
        <div className="d-lg-block d-none me-5 pe-3">
          <Logo />
        </div>
        <div className="mobile-logo">
        <NavbarBrand href="/">
          <LogoSmall/>
        </NavbarBrand>
        </div>
        <Button
          color="primary"
          className=" d-lg-none"
          onClick={() => showMobilemenu()}
        >
          Menu
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="primary"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x">...</i>
          ) : (
            <i className="bi bi-three-dots-vertical">...</i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen} className="mobile-collapse">
        <Nav className="ms-auto" navbar>
          <NavItem>
          {user && (
            <div >
              <span><strong>Hello </strong> {user.first_name}</span>
            </div> 
            )}
          </NavItem>
        </Nav>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent">
            <img
                src={previewSource}
                alt="profile"
                className="rounded-circle"
                style={{ width: '39px', height: '39px' }}
              />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Info</DropdownItem>
            <DropdownItem onClick={toggleOffcanvas}>Edit Photo</DropdownItem>
            <Offcanvas direction="end" isOpen={showOffcanvas} toggle={toggleOffcanvas} placement="start">
            <OffcanvasHeader>
              <div className="header-content">
                <h4>Edit Photo</h4>
                <Button color="warning" className="close" onClick={handleCloseCanvas}>X</Button>
              </div>
            </OffcanvasHeader>
    
              <OffcanvasBody>
              
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="photo">Upload Photo</Label>
                      <Input type="file" name="avatar" id="photo" onChange={handleFileInputChange} />
                    </FormGroup>
                      <Card>
                        <CardImg top width="100%" src={previewSource} alt="Preview" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}/>
                      </Card>
                    <Button color="success" type="submit">Save Changes</Button>
                </Form>
                
              </OffcanvasBody>
              
            </Offcanvas>
            <DropdownItem divider />
            <DropdownItem>
              <Link to="/billing" style={{ textDecoration: 'none', color: 'inherit' }}>Billing</Link>
            </DropdownItem>
            <DropdownItem onClick={handleLogout}>Log Out</DropdownItem>
            {/* <DropdownItem onClick={handleClick}>Logout</DropdownItem> */}
          </DropdownMenu>
        </Dropdown>
      </Collapse>
    </Navbar>
  );
};

export default Header;
