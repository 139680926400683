import { Link } from "react-router-dom";
import HFLogoSmall from './helperfox-logo.png'

const LogoSmall = () => {
  return (
    <Link to="/">
      <img 
      src={HFLogoSmall} 
      style={{ width: '145px', height: '30px', marginLeft: '10px', marginRight: '70px' }}
      alt="Logo" />
    </Link>
  );
};

export default LogoSmall;