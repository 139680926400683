import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, FormGroup, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, Input, Collapse, Table } from "reactstrap";
import { useProbindsContext } from '../../hooks/useProbindsContext';
import { useAuthContext } from '../../hooks/useAuthContext'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Holidays from 'date-holidays';
import { format } from 'date-fns';
import axios from "axios";
import { FaAngleDown, FaAngleUp, FaCheck, FaTimes } from 'react-icons/fa';
import API_BASE_URL from "../../apiConfig"; 

const Availability = () => {
    const { probinds } = useProbindsContext();
    const { user } = useAuthContext()
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [selectedDays, setSelectedDays] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']);
    const [workingHours, setWorkingHours] = useState('Business Hours');
    const [isOpen, setIsOpen] = useState(false);
    const [customHours, setCustomHours] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [holidays, setHolidays] = useState([]);
    const [visibleRows, setVisibleRows] = useState(4);
    const [holidayName, setHolidayName] = useState('');
    const [holidayDate, setHolidayDate] = useState('');
    const [showAddHolidayFields, setShowAddHolidayFields] = useState(false);
    const [availability, setAvailability] = useState({});

    const userId = user._id

    // Fetch availability data from the backend
    useEffect(() => {
        const fetchAvailabilityData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/auth/availability/${userId}`, {
                    params: {
                        zipCode: probinds[0].zipCode
                    }
                });

                if (response.data && response.data.workingDays) {
                    setAvailability(response.data.workingDays);
                }
                if (response.data && response.data.workingHours) {
                    setWorkingHours(response.data.workingHours);
                }
                if (response.data && response.data.holidays) {
                    setHolidays(response.data.holidays);
                }


            } catch (error) {
                console.error('Error fetching availability data:', error);
                throw error;
            }
        };
        fetchAvailabilityData();
    }, [probinds]);



    //Update date function
    const updateAvailabilityData = async () => {

        try {
            const newData = {
                workingDays: availability,
                workingHours: workingHours,
                holidays: holidays,
                zipcode: probinds[0].zipCode
            };
         
            const response = await axios.patch(`${API_BASE_URL}/api/auth/availability/${userId}`, newData);

            setShowOffcanvas(false);

          
            return toast("Availability Successfully Updated", {
                className: "toast-success",
                bodyClassName: "toast-success",
            });
        } catch (error) {
            console.error('Error updating availability data:', error);
            return toast("Error Saving Availability. Please Try Again.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
            })
            throw error;
        }
    };

    // Function to delete a holiday listing in the backend
    const deleteHolidayListing = async (holidayId) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/api/auth/holidays/${holidayId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting holiday listing:', error);
            throw error;
        }
    };


    // Set daily availability ///////////////////////////////////////////////////////////////////////////////
    const handleCheckboxChange = (day) => {
        setAvailability((prevAvailability) => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                available: !prevAvailability[day]?.available,
            },
        }));
    };




    // Set hourly availability //////////////////////////////////////////////////////////////////////////////  
    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
        
        setWorkingHours(selectedValue);

        if (selectedValue === 'Standard Hours (9am - 5pm)') {
            setAvailability((prevState) => {
                const updatedAvailability = { ...prevState };
                Object.entries(updatedAvailability).forEach(([day, details]) => {
                    if (details.available) {
                        updatedAvailability[day] = {
                            ...details,
                            from: '09:00:00',
                            to: '17:00:00',
                        };
                    }
                });
                return updatedAvailability;
            });
        } else if (selectedValue === "Extended Hours (7am - 7pm)") {
            
            setAvailability((prevState) => {
          
                const updatedAvailability = { ...prevState };
             
                Object.entries(updatedAvailability).forEach(([day, details]) => {
                    
                    if (details.available) {
                       
                        updatedAvailability[day] = {
                            ...details,
                            from: '07:00:00',
                            to: '18:59:00',
                        };
                    }
                });
                
                
                return updatedAvailability;
            });
        } else if (selectedValue === 'Open 24 Hours') {
            setAvailability((prevState) => {
                const updatedAvailability = { ...prevState };
                Object.entries(updatedAvailability).forEach(([day, details]) => {
                    if (details.available) {
                        updatedAvailability[day] = {
                            ...details,
                            from: '00:00:00',
                            to: '23:59:59',
                        };
                    }
                });
                return updatedAvailability;
            });
        } else if (selectedValue === 'Custom Hours') {
            const from = '09:00'; 
            const to = '17:00'; 

            const updatedAvailability = { ...availability };
            for (const day of Object.keys(updatedAvailability)) {
                updatedAvailability[day] = {
                    ...updatedAvailability[day],
                    available: false,
                    from: from !== '' ? `${from}:00` : '',
                    to: to !== '' ? `${to}:00` : '',
                };
            }
            setAvailability(updatedAvailability);
        }
    };

    const handleCustomHoursChange = (day, value) => {
        const updatedAvailability = { ...availability };
        updatedAvailability[day] = {
            ...updatedAvailability[day],
            available: true,
            from: value.from,
            to: value.to,
        };
        setAvailability(updatedAvailability);
    };

    

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleDayToggle = (day) => {
        setAvailability(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                available: !prevState[day].available
            }
        }));
    };

    const handleTimeChange = (day, field, value) => {
        setAvailability(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [field]: value
            }
        }));
    };

    const formatDate = (date) => {
        return format(new Date(date), 'MMM. d, yyyy');
    };

    const handleSubmitHoliday = (e) => {
        e.preventDefault();
        const customHoliday = {
            name: holidayName,
            date: holidayDate,
        };
        setHolidays([...holidays, customHoliday]);
        setShowAddHolidayFields(false);
        setHolidayName('');
        setHolidayDate('');
    };


    const handleSeeMore = () => {
        setVisibleRows(holidays.length);
    };

    const handleSeeLess = () => {
        setVisibleRows(5);
    };

    const handleAddHoliday = () => {
        setShowAddHolidayFields(true);
    };

    const handleCancelAddHoliday = () => {
        setShowAddHolidayFields(false);
        setHolidayName('');
        setHolidayDate('');
    };

    const handleDeleteHoliday = (date) => {
        setHolidays(holidays.filter((holiday) => holiday.date !== date));
    };

   

    return (
        <>
            <ToastContainer />
            <Card>
                <CardBody className="custom-card-body" style={{ height: '245px' }}>
                    <h3>Availability</h3>
                    <hr />
                    <div>
                        <strong>WORKING HOURS </strong>: {workingHours} ({probinds[0].time_zone})
                    </div>
                    <br />
                    <div>
                        {availability && (
                            <div><strong>DAYS AVAILABILITY : </strong>
                                {Object.entries(availability)
                                    .filter(([day, details]) => details.available)
                                    .map(([day, details], index, arr) => (
                                        <React.Fragment key={day}>
                                            {index !== 0 && ', '}
                                            {day.substring(0, 3)}
                                            {index !== arr.length - 1 && ' '}
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>

                    <FormGroup>
                        <div className="d-flex justify-content-end">
                            <Button color="warning" onClick={() => setShowOffcanvas(true)}>
                                Edit Your Availability
                            </Button>
                        </div>
                        <Offcanvas direction="end" isOpen={showOffcanvas} toggle={() => setShowOffcanvas(!showOffcanvas)} style={{ width: '600px' }}>
                            <OffcanvasHeader toggle={() => setShowOffcanvas(!showOffcanvas)}>
                                <br />
                                <h4>Availability</h4>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>
                                <FormGroup>
                                    <Label><strong>DAYS AVAILABLE:</strong></Label><br />
                                    <div style={{ marginLeft: '50px' }}>
                                        {/* Add code here for selecting days of week */}
                                        {Object.entries(availability).map(([day, details]) => (
                                            <FormGroup check inline key={day}>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={details.available}
                                                        onChange={() => handleCheckboxChange(day)}
                                                    />
                                                    {day.substring(0, 3)}
                                                </Label>
                                            </FormGroup>
                                        ))}
                                    </div>
                                </FormGroup>
                                <FormGroup style={{ marginTop: '60px' }}>
                                    <Label>
                                        <strong>WORKING HOURS:</strong>
                                    </Label>
                                    <div style={{ marginLeft: '50px' }}>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="workingHours"
                                                    checked={workingHours === 'Standard Hours (9am - 5pm)'}
                                                    value="Standard Hours (9am - 5pm)"
                                                    onChange={handleRadioChange}
                                                />
                                                {' '}
                                                Standard Hours (9am - 5pm)
                                            </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="workingHours"
                                                    checked={workingHours === 'Extended Hours (7am - 7pm)'}
                                                    value="Extended Hours (7am - 7pm)"
                                                    onChange={handleRadioChange}
                                                />
                                                {' '}
                                                Extended Hours (7am - 7pm)
                                            </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="workingHours"
                                                    checked={workingHours === 'Open 24 Hours'}
                                                    value="Open 24 Hours"
                                                    onChange={handleRadioChange}
                                                />
                                                {' '}
                                                Open 24 Hours
                                            </Label>
                                        </FormGroup>
                                        <br />
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="workingHours"
                                                    value="Custom Hours"
                                                    checked={workingHours === 'Custom Hours'}
                                                    onChange={handleRadioChange}
                                                />
                                                {' '}
                                                Custom Hours
                                            </Label>
                                        </FormGroup>
                                        <br />
                                        <Collapse isOpen={workingHours === 'Custom Hours'}>
                                            <div style={{ marginLeft: '50px' }}>
                                                {Object.keys(availability).map((day) => (
                                                    <FormGroup check key={day}>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={availability[day].available}
                                                                onChange={() => handleDayToggle(day)}
                                                            />
                                                            {day}
                                                        </Label>
                                                        {availability[day].available && (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div>
                                                                    <span style={{ marginRight: '5px' }}>From:</span>
                                                                    <Input
                                                                        type="time"
                                                                        value={availability[day].from}
                                                                        onChange={(e) =>
                                                                            handleCustomHoursChange(day, {
                                                                                from: e.target.value,
                                                                                to: availability[day].to,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div style={{ marginLeft: '20px' }}>
                                                                    <span style={{ marginLeft: '10px', marginRight: '5px' }}>To:</span>
                                                                    <Input
                                                                        type="time"
                                                                        value={availability[day].to}
                                                                        onChange={(e) =>
                                                                            handleCustomHoursChange(day, {
                                                                                from: availability[day].from,
                                                                                to: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                ))}
                                                
                                            </div>
                                        </Collapse>
                                    </div>
                                </FormGroup>

                                <FormGroup style={{ marginTop: '60px' }}>
                                    <div>
                                        <Label><strong>TIME OFF & OBSERVED HOLIDAYS</strong></Label>
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td colSpan={3} style={{ textAlign: 'center' }}>
                                                        {!showAddHolidayFields ? (
                                                            <Button color="warning" onClick={handleAddHoliday}>
                                                                Click To Add Holidays/Time Off
                                                            </Button>
                                                        ) : (
                                                            <Form onSubmit={handleSubmitHoliday}>
                                                                <tr>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            id="holidayDescription"
                                                                            placeholder="Description"
                                                                            value={holidayName}
                                                                            onChange={(e) => setHolidayName(e.target.value)}
                                                                            required
                                                                            style={{ width: '100%', marginRight: '5px' }}
                                                                        />
                                                                    </td>
                                                                    <td>

                                                                        <Input
                                                                            type="date"
                                                                            id="holidayDate"
                                                                            value={holidayDate}
                                                                            onChange={(e) => setHolidayDate(e.target.value)}
                                                                            required
                                                                            style={{ width: '135%', marginLeft: '7px' }}
                                                                        />

                                                                    </td>
                                                                    <td style={{ display: 'flex' }}>

                                                                        <Button color="success" type="submit" size="sm" className="submit-button" style={{ marginLeft: '75px', marginRight: '5px' }}> {/* CSS class for Submit button */}
                                                                            <FaCheck />
                                                                        </Button>
                                                                        <Button color="danger" size="sm" onClick={handleCancelAddHoliday} className="cancel-button"> {/* CSS class for Cancel button */}
                                                                            <FaTimes />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </Form>
                                                        )}
                                                    </td>
                                                </tr>
                                                {holidays.slice(0, visibleRows).map((holiday) => (
                                                    <tr key={holiday.date}>
                                                        <td>{holiday.name}</td>
                                                        <td>{formatDate(holiday.date)}</td>
                                                        <td
                                                            style={{ cursor: 'pointer', color: 'red', fontWeight: 'bold' }}
                                                            onClick={() => handleDeleteHoliday(holiday.date)}
                                                        >
                                                            X
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        {visibleRows < holidays.length ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    color: 'blue',
                                                    marginTop: '10px',
                                                }}
                                                onClick={handleSeeMore}
                                            >
                                                <FaAngleDown style={{ marginRight: '5px' }} /> See More
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    color: 'blue',
                                                    marginTop: '10px',
                                                }}
                                                onClick={handleSeeLess}
                                            >
                                                <FaAngleUp style={{ marginRight: '5px' }} /> See Less
                                            </div>
                                        )}
                                    </div>



                                </FormGroup>
                                <div className="d-flex justify-content-end">
                                    <Button color="primary" onClick={updateAvailabilityData}>
                                        Save Changes
                                    </Button>
                                </div>
                            </OffcanvasBody>
                        </Offcanvas>
                    </FormGroup>
                </CardBody>
            </Card>
        </>
    );
}

export default Availability;