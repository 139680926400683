import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = ({ color }) => {
    return (
        <div className='d-flex align-items-center'>
            <Spinner style={{ borderWidth: 3, color: color }} color={!color && 'primary'}>
                Loading...
            </Spinner>
        </div>
    )
}

export default Loader;