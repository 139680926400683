import React, { useEffect, useRef } from 'react';
import { Card, CardBody } from "reactstrap";
import API_BASE_URL from "../../apiConfig"; 

const CoverageMap = ({ zipCode, radius, longitude, latitude }) => {
  const mapContainerRef = useRef(null);

  const apiKey = 'AIzaSyBTyxIk3QTWGfwDVP7a9t2iUkwxFO4PLMg';

  useEffect(() => {
    const fetchZipCodeBoundaries = async () => {
      try {

        // Initialize the Google Map
        const map = new window.google.maps.Map(mapContainerRef.current, {
          center: { lat: latitude, lng: longitude },
          zoom: 14,
        });

        // Draw the circle representing the radius
        const circle = new window.google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map,
          center: { lat: latitude, lng: longitude },
          radius: radius * 1000, // Convert radius from kilometers to meters
        });

        // Fit the map to the circle bounds
        map.fitBounds(circle.getBounds());
      } catch (error) {
        console.error('Error fetching zip code boundaries:', error);
      }
    };

    // Load the Google Maps JavaScript API
    const loadMap = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      script.onload = fetchZipCodeBoundaries;
      document.head.appendChild(script);
    };

    if (longitude && latitude) {
      loadMap();
    }
  }, [zipCode, radius, longitude, latitude]);

  return (
    <Card>
      <CardBody className="custom-card-body">
        <h3>General Coverage Map</h3>
        <div ref={mapContainerRef} style={{ width: '100%', height: '400px' }} />
      </CardBody>
    </Card>
  );
};

export default CoverageMap;