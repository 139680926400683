import React from "react";
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/scss/style.scss";
import { AuthContextProvider } from "./context/AuthContext";
import { ProbindsContextProvider } from "./context/ProbindContext";
import { PayModalProvider } from "./context/PayModalContext";

// const recaptchaKey = '6LcpBg0nAAAAAOd3A65qd9uc-jlQ2jXtgBgwtnZk';

ReactDOM.render(
  // <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey || ''} language='en'>
    <AuthContextProvider>
      <ProbindsContextProvider>
          <PayModalProvider>
            <App />
          </PayModalProvider>
      </ProbindsContextProvider>
    </AuthContextProvider>,
  // </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
