import Input from "../Input/Input";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
// import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useCallback, useState } from "react";
import { isEmpty, isEmail, isLength, isMatch, hasDigit, hasSpecialCharacter, hasUppercase } from "../helper/validate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import API_BASE_URL from "../../apiConfig"; 
import {
  Card,
  CardBody
} from "reactstrap";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  cf_password: "",
};

const Register = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const [isUS, setIsUS] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);
  const { first_name, last_name, email, phone_number, password, cf_password } = data;

  const [token, setToken] = useState(null);

  // const handleRecaptchaVerify = useCallback((token) => {
  //   setToken(token)
  // }, [])

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;

    if (!isUS) {
      toast("Please change the country back to US.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
      isValid = false;
    }

    if (!isAgreed) {
      toast("Please agree to the terms and conditions.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
      isValid = false;
    }

    return isValid;
  };

  const handlePhoneChange = (value) => {
    setData({ ...data, phone_number: value });
  };


  const handleCountryChange = (countryCode) => {
    if (countryCode !== 'US') {
      toast("Our services are currently not available in this country.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
      setIsUS(false);
    } else {
      setIsUS(true);
    }
  };


  const handleClick = () => {
    setVisible(!visible);
  };





  const register = async (e) => {
    e.preventDefault();
    // check fields
    // if (token) {
      if (isEmpty(first_name))
        return toast("Please enter your first name.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      if (isEmpty(last_name))
        return toast("Please enter your last name.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      if (isEmpty(phone_number))
        return toast("Please enter your phone number.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      if (isEmpty(password))
        return toast("Please enter a password.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      if (isEmpty(cf_password))
        return toast("Please confirm your password.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });


      // check email
      if (!isEmail(email))
        return toast("Please enter a valid email address.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check password length
      if (isLength(password))
        return toast("Password must be at least 12 characters.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check match
      if (!isMatch(password, cf_password))
        return toast("Passwords do not match.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check password for digits
      if (!hasDigit(password))
        return toast("Please include a digit in the password (0 - 9).", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check password for special characters
      if (hasSpecialCharacter(password))
        return toast("Please include a special character in the password (! @ # $ % ^ & *).", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check password for uppercase letter
      if (hasUppercase(password))
        return toast("Please include an upper case letter in your password.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });

      if (!validateForm()) {
        return; 
      }

      try {
     
        const res = await axios.post(`${API_BASE_URL}/api/auth/register`, {
          first_name,
          last_name,
          email: email.toLowerCase(),
          phone_number,
          password,
        });
        toast(res.data.msg, {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
      } catch (err) {
        toast(err.response.data.msg, {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      }
      handleReset();
    // }
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, first_name: "", last_name: "", email: "", phone_number: "", password: "", cf_password: "" });
  };

  return (
    <>
      <ToastContainer />
      <div className="authInputArea">
        <h1>Sign up for a business account</h1>
        <h5>Enter service professional account information</h5>
        <Card>
          <CardBody className="custom-card-body">
            <form onSubmit={register}>
              <Input
                type="text"
                text="First Name"
                name="first_name"
                handleChange={handleChange}
              />
              <Input
                type="text"
                text="Last Name"
                name="last_name"
                handleChange={handleChange}
              />
              <Input
                type="text"
                text="Email"
                name="email"
                handleChange={handleChange}
              />
              <PhoneInput
                type="tel"
                placeholder="Phone Number"
                name="phone_number"
                value={data.phone_number}
                defaultCountry="US"
                onChange={handlePhoneChange}
                onCountryChange={handleCountryChange}
              />
              <Input
                name="password"
                type={visible ? "text" : "password"}
                icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
                text="Password"
                handleClick={handleClick}
                handleChange={handleChange}
              />
              <Input
                name="cf_password"
                type={visible ? "text" : "password"}
                icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
                text="Confirm Password"
                handleClick={handleClick}
                handleChange={handleChange}
              />

              <div className="agreement_checkbox" style={{ width: '360px', display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={isAgreed}
                    onChange={() => setIsAgreed(!isAgreed)}
                    style={{ marginRight: '8px' }}  
                  />
                  <label>
                      By checking this box, I fully agree with the
                    <a href="https://helperfox.com/terms-conditions/" target="_blank" rel="noopener noreferrer"> Terms and Conditions </a> 
                    and 
                    <a href="https://helperfox.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
                  </label>
              </div>

              <div className="login_btn">
                <button type="submit">register</button>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
      {/* <GoogleReCaptcha onVerify={handleRecaptchaVerify} /> */}
    </>
  );
};

export default Register;
