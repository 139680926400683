import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

const RefundPolicy = () => {
    return (  
        <Card className="custom-card-body">
        <CardBody>
            <h2>Return and Cancellation Policy for helperfox.com</h2>
            <hr />
            <p>Last Updated: August 12, 2023</p>
            <Row>
                <Col>
                <p>
                    Welcome to helperfox.com (the "Website"), provided by Clientain, LLC ("we," "us," "our"). This Return and Cancellation Policy outlines the terms and conditions regarding cancellations, refunds, and charges for our subscription-based service.
                </p>
                </Col>
            </Row>
            <Row>
                <Col>
                <h3>Subscription Plans</h3>
                <p>
                    We offer two subscription plans: a monthly subscription at $9.99 per month and an annual subscription at $119.88 per year. By subscribing to our service, you agree to the terms outlined in this Return and Cancellation Policy.
                </p>
                </Col>
            </Row>
            <Row>
                <Col>
                <h3>Cancellation of Subscription</h3>
                <p>
                    Customers have the right to cancel their subscription at any time. To cancel a subscription, please [provide instructions on how customers can cancel, such as contacting customer support or accessing their account settings]. Once a subscription is canceled, the customer will not be charged for any future billing cycles.
                </p>
                </Col>
            </Row>
            <Row>
                <Col>
                <h3>Refunds</h3>
                <p>
                    Refunds for canceled subscriptions are subject to the following terms:
                </p>
                <ol>
                    <li>
                    <strong>Monthly Subscription:</strong> If you cancel a monthly subscription, you will be charged for the current month, and your subscription will remain valid until the last day of the month. We do not provide refunds for the current month's charges.
                    </li>
                    <li>
                    <strong>Annual Subscription:</strong> If you cancel an annual subscription, you will be charged up to the last day of the current month. We do not provide refunds for the remaining months of the annual subscription.
                    </li>
                </ol>
                <p>
                    Please note that tax charges associated with your subscription are non-refundable. Any applicable taxes collected during the initial purchase or subsequent renewals are not eligible for refund.
                </p>
                </Col>
            </Row>
            <Row>
                <Col>
                <h3>Refund Processing</h3>
                <p>
                    Refunds, if applicable, will be processed using the same payment method used for the original purchase. Please allow [insert estimated timeframe] for the refund to be reflected in your account.
                </p>
                </Col>
            </Row>
            <Row>
                <Col>
                <h3>Contact Us</h3>
                <p>
                    If you have any questions or need assistance with canceling your subscription or requesting a refund, you may contact us using the following methods:
                    <br />
                    Email: contact@helperfox.com
                    <br />
                    Phone: 1 866 211 2779
                    <br />
                    Postal Mail: 177 Huntington Ave, Suite 1700 Boston, MA 02116 
                </p>
                </Col>
            </Row>
            <Row>
                <Col>
                <p>
                    <strong>Changes to Return and Cancellation Policy</strong>
                    <br />
                    We may update this Return and Cancellation Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. The most current version of this Return and Cancellation Policy will be posted on this page.
                </p>
                </Col>
            </Row>
            </CardBody>
        </Card>
        
    );
}
 
export default RefundPolicy;