const serviceDescriptions = {
    plumbing: [
        "With years of plumbing experience, I've tackled everything from stubborn clogs to full pipe installations. My commitment is to provide efficient solutions, ensuring every drop counts.",
        "As a dedicated plumber, I believe in more than just fixing leaks. It’s about providing peace of mind, ensuring families have safe, hassle-free homes. Let me take care of your plumbing needs.",
        "Every pipe and faucet tells a story, and I've listened to them all. With years of hands-on experience, I offer not just services, but plumbing solutions that last.",
        "I pride myself on being a plumbing expert who values integrity. From routine fixes to complex installations, I approach each task with dedication, ensuring quality and client satisfaction."
    ],
    electrical: [
        "As an experienced electrician, I've illuminated countless homes and powered numerous businesses. I prioritize safety and precision, ensuring every wire is perfectly placed.",
        "Electricity is more than just switches and bulbs. As a certified professional, I guarantee quality service, ensuring every appliance and fixture works seamlessly in your space.",
        "With a vast experience in the electrical domain, I provide solutions that light up your world. My work is meticulous, ensuring every project is completed to perfection.",
        "Electrical issues can be complex, but with my expertise, they become manageable. I’m dedicated to providing efficient, safe electrical services, lighting up lives one wire at a time."
    ],
    roofing: [
        "Roofs are more than just shelters; they're security. With years of expertise, I provide roofing solutions that stand the test of time, ensuring every home is safe and sound.",
        "As a seasoned roofer, I've covered countless homes, understanding the nuances of each tile and shingle. My mission is to provide roofs that not only protect but also add aesthetic appeal.",
        "Crafting roofs that last is my forte. With an eye for detail, I ensure every roof I work on is sturdy, reliable, and perfectly suited for the building beneath.",
        "Roofing is an art, and I’m its passionate artist. From installations to repairs, I provide services that merge functionality with aesthetic beauty, ensuring roofs that resonate."
    ],
    moving: [
        "Moving homes can be stressful, but with my expertise, I aim to make it seamless. I handle every item with care, ensuring safe, timely transitions to your new space.",
        "As a professional mover, I've facilitated countless relocations. My goal is to make each move smooth, treating every possession as my own, ensuring it reaches its destination intact.",
        "Every move tells a story, and I'm here to make sure yours is perfect. With precision and care, I ensure every box, every furniture piece, is relocated without a hitch.",
        "Relocating is more than just shifting things. It's about transitioning memories. With years in the moving business, I promise efficient, heartfelt services, ensuring you settle in swiftly."
    ],
    hvac: [
        "I ensure homes stay cozy in winter and cool in summer. With a passion for HVAC, I deliver comfort to every room and corner.",
        "Heating and cooling systems are my expertise. I provide solutions that create the perfect indoor atmosphere, tailored to your needs.",
        "From furnaces to air conditioners, I handle them all. My focus is to maintain optimal temperatures, ensuring every moment indoors is pleasant.",
        "I'm an HVAC specialist who values precision. Trust me to balance your indoor climates, offering a comfortable environment year-round."
    ],
    
    landscaping: [
        "Nature's artistry inspires me. I shape and transform outdoor spaces, turning simple gardens into breathtaking landscapes.",
        "Every plant, every stone has a purpose. As a landscaper, I craft outdoor sanctuaries that resonate with serenity and beauty.",
        "Landscaping is my canvas, and nature is my paint. I blend colors and textures, ensuring gardens thrive and flourish.",
        "Outdoor transformations are my specialty. I design landscapes that not only beautify spaces but also nurture the soul."
    ],
    painting: [
        "I transform spaces with every brushstroke, capturing your vision in vibrant hues.",
        "Every wall is a canvas waiting to tell a story. As a painter, I'm here to bring that story to life.",
        "With precision and care, I turn rooms into masterpieces, ensuring your space mirrors your personality.",
        "From prep to finish, painting is more than a job to me; it's an art. Trust me to beautify your walls."
    ],

    appliancerepair: [
        "Modern homes rely on appliances, and I ensure they work seamlessly. Your convenience is my mission.",
        "Appliances falter, but with my expertise, I restore their efficiency, bringing comfort back to your home.",
        "When machines act up, I'm the go-to fixer. Ensuring your appliances serve you flawlessly is my top priority.",
        "I understand appliances inside and out. Trust me to rejuvenate them, optimizing their performance for your household."
    ],

    treeservices: [
        "Trees are nature's wonders, and I care for them with expertise, ensuring they remain healthy and majestic.",
        "From pruning to removal, I offer comprehensive tree services, prioritizing safety and aesthetics.",
        "I understand the language of trees. Trust me to manage their growth and health, ensuring they thrive.",
        "Trees add value and beauty. My passion is to maintain their grandeur, serving as a guardian of your green canopy."
    ],

    handyperson: [
        "No task is too small; I tackle home repairs with diligence, ensuring everything's in perfect shape.",
        "From leaky faucets to creaky doors, I'm your handyperson, ensuring your home runs without a hitch.",
        "Versatility is my strength. Trust me to fix, install, and maintain, making every corner of your home flawless.",
        "I'm the all-rounder your home needs. With tools in hand and expertise in mind, I address every home challenge."
    ],
    pestcontrol: [
        "Every home deserves to be pest-free. With precision and care, I eliminate intruders and protect your haven.",
        "In the battle against pests, I bring expertise and dedication. Trust your home's safety in my hands.",
        "Pests are no match for my techniques. I ensure a serene, critter-free environment for every client.",
        "From detection to removal, I offer comprehensive pest solutions. Your home's peace is my top mission."
    ],

    cleaning: [
        "A clean home is a happy home. With meticulous attention, I ensure every nook and cranny shines.",
        "I believe in transforming spaces through deep cleaning, bringing out the best in your household.",
        "Every surface, every corner, I ensure they gleam. Trust me to rejuvenate your living space.",
        "I take pride in making homes sparkle, ensuring a fresh and inviting environment for every family."
    ],

    photographer: [
        "Through my lens, I capture memories, ensuring moments are frozen in time for you to cherish.",
        "Photography is an art, and I'm dedicated to framing life's beautiful instances, one click at a time.",
        "With a keen eye for detail, I transform moments into lasting memories, ensuring every shot speaks volumes.",
        "Life is full of stories, and my camera is the pen. Trust me to narrate your tale with every capture."
    ],

    furnitureassembly: [
        "Furniture is the backbone of a room. I ensure it stands strong, assembled with utmost precision.",
        "From flat packs to intricate designs, I masterfully piece together furniture, optimizing its function and form.",
        "With a knack for details, I bring furniture to life, ensuring each piece complements your space perfectly.",
        "I believe in crafting comfort. Trust me to assemble your furniture, guaranteeing durability and style."
    ],

    gutterservices: [
        "Water should flow, not overflow. I ensure gutters are clear, protecting your home's integrity.",
        "From cleaning to repair, I offer holistic gutter solutions, safeguarding your property against the elements.",
        "Gutters might be overhead, but they're never out of mind. I ensure they function seamlessly, rain or shine.",
        "I specialize in keeping gutters pristine, ensuring your home remains shielded and unblemished."
    ],

    locksmiths: [
        "Locked out or in need of enhanced security? I'm here to provide tailored solutions for your peace of mind.",
        "I understand the importance of safety. With skill and precision, I address all your lock-related needs.",
        "From installations to emergencies, I'm the key master ensuring accessibility and security for your premises.",
        "Security is paramount. Trust in my expertise to provide steadfast lock solutions for your protection."
    ],
    hauling: [
        "Clearing clutter is my specialty. I swiftly and responsibly haul away unwanted items, rejuvenating your space.",
        "From attics to basements, I transform spaces by removing excess, giving you room to breathe.",
        "Got junk? I'm your solution. Trust in me to declutter and refresh your environment efficiently.",
        "I pride myself in swift, hassle-free hauling. Whatever the junk, I ensure your space is immaculate."
    ],

    towservices: [
        "Stranded? I'm here to tow and assist, ensuring your vehicle's safety and your peace of mind.",
        "Every vehicle deserves care. Rely on me to transport your car securely, no matter the distance.",
        "On the road, mishaps happen. Trust in my timely and safe towing solutions whenever you need.",
        "With expertise in vehicle handling, I guarantee smooth, secure tows, getting you back on track swiftly."
    ],

    generalcontractors: [
        "Building dreams brick by brick. I oversee projects with precision, ensuring your vision comes to life.",
        "From conception to completion, I bring construction projects to fruition with unmatched dedication.",
        "Crafting structures that last, I manage every project detail, ensuring optimal quality and design.",
        "Your construction aspirations are my command. I ensure every build mirrors your desires perfectly."
    ],

    interiordesigners: [
        "Spaces tell stories. I craft interiors that resonate with your personality, making every room a masterpiece.",
        "Designing interiors is my passion. Trust me to transform spaces into enviable havens of style and comfort.",
        "I infuse life into rooms, ensuring each design choice reflects you. Together, let's make magic indoors.",
        "With an artistic touch, I craft interiors that aren't just spaces but expressions of your essence."
    ],

    flooringtile: [
        "The foundation matters. I lay down flooring and tiles with precision, ensuring durability and aesthetics.",
        "From ceramics to hardwood, I ensure floors gleam, enhancing the beauty and value of your space.",
        "Every floor I lay is a testament to craftsmanship. Trust me for quality work that stands the test of time.",
        "Crafting floors to perfection, I ensure every step you take indoors feels right."
    ],

    doorswindows: [
        "Doors and windows are gateways. I install and repair, ensuring they frame your world beautifully.",
        "Every entrance and view matters. Rely on me to enhance your home's appeal and functionality.",
        "With an eye for design, I ensure doors and windows seamlessly blend with your home's aesthetic.",
        "Inviting light and security, I specialize in crafting door and window solutions that elevate living."
    ],

    architects: [
        "Designing more than buildings, I craft experiences. Let's shape the future of spaces together.",
        "In the realm of blueprints, I envision structures that merge form, function, and innovation.",
        "Each structure I design isn't just brick and mortar; it's a dream realized. Let's build the extraordinary.",
        "With creativity and engineering, I design spaces that are both functional and aesthetically pleasing."
    ],

    fenceInstallation: [
        "Boundaries can be beautiful. I install fences that protect and enhance your property's appeal.",
        "With precision and design sense, I ensure fences stand tall, complementing your outdoor aesthetics.",
        "From pickets to panels, I craft fences that define and beautify your space.",
        "Setting boundaries is an art. Trust in me to install fences that resonate with both safety and style."
    ],

    realestate: [
        "Finding homes, not just houses. I guide you through real estate journeys with expertise and care.",
        "Every property has a story. Let me connect you to spaces that align with your life's narrative.",
        "Navigating real estate can be intricate. With me by your side, every decision becomes a step closer to home.",
        "From listings to closings, I'm dedicated to ensuring your property aspirations become a reality."
    ]

};

export default serviceDescriptions;
