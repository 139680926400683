import { useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, ListGroup, ListGroupItem  } from "reactstrap";
import { usePayModalContext } from "../../hooks/usePayModalContext";
import { AuthContext } from "../../context/AuthContext";
import API_BASE_URL from "../../apiConfig"; 
import axios from "axios";
import dotenv from 'dotenv';
dotenv.config();

const PayModal = () => {
  const { modalOpen, toggleModal } = usePayModalContext();
  const { user } = useContext(AuthContext);
  const monthlyPrice = process.env.REACT_APP_MONTHLY_PRICE
  const yearlyPrice = process.env.REACT_APP_YEARLY_PRICE
  
  const createSession = async (priceId) => {
    const { data: response } = await axios.post(`${API_BASE_URL}/api/auth/session`,
      {
        priceId,
        email: user.email,
      }
    );

    window.location.href = response.url;
  };


  return (

    <div className="d-flex justify-content-end" style={{ marginTop: '20px' }}>
        <Button color="primary" onClick={toggleModal}>
          Upgrade to Standard Plan
        </Button>
          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}><h3><strong>Standard Plan Details</strong></h3></ModalHeader>
            <ModalBody>    
              <br />
              <Table>
                <thead>
                  <tr>
                    <th>Features</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Instant listing in your area</td>
                  </tr>
                  <tr>
                    <td>Up to 35 miles of coverage for your services</td>
                  </tr>
                  <tr>
                    <td>Multichannel presence (Phone, Apps, SMS, Social Media, Websites)</td>
                  </tr>
                  <tr>
                    <td>Greater exposure for your services</td>
                  </tr>
                  <tr>
                    <td>Same cost as three cups of coffee ☕☕☕ </td>
                  </tr>
                </tbody>
              </Table>
            <br/>
            <br/>
            
            <p className="d-flex justify-content-center">Cancel anytime, no commitments, just convenience.</p>
            <br/>
            {/* <div className="d-flex justify-content-center gap-3">
                <Button color="primary" onClick={() => createSession(monthlyPrice)}>
                  $14.99/Month
                </Button>
                <Button color="primary" onClick={() => createSession(yearlyPrice)}>
                  $179.99/Year
                </Button>
                </div> */}
                <div className="text-center">
                  <Button color="primary" size="lg" onClick={() => createSession(monthlyPrice)}>
                    Start Subscription - $4.99/Month
                  </Button>
                </div>
              </ModalBody>
            
              <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
            </ModalFooter>
          </Modal>
      </div>

  );
};

export default PayModal;
