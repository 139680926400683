import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

const Charges = () => {
    return (  
      <Card className="custom-card-body">
      <CardBody>
        <h2>Simplifying Payments: How We Charge Our Valued Customers</h2>
        <hr />
        <p>
          In our commitment to providing seamless and hassle-free services, we believe that transparency and simplicity extend to every aspect of your experience with us, including the way we handle payments. At [Your Company Name], we offer two convenient subscription options, empowering you to choose the plan that best aligns with your needs. This article dives into the details of how we charge our customers, the flexibility we provide, and how we ensure the utmost security in the process.
        </p>
        <h3>Two Flexible Subscription Options</h3>
        <p>
          When it comes to subscriptions, we understand that different customers have different preferences. That's why we offer you two flexible payment choices: a monthly subscription of $9.99 or an annual subscription of $119.88. Whether you prefer the convenience of a monthly commitment or the value of an annual plan, the choice is entirely yours.
        </p>
        <h3>Auto-Renewal for Seamless Service</h3>
        <p>
          We value your time and commitment, which is why we've implemented an auto-renewal feature for our annual subscribers. At the end of your subscription year, unless otherwise instructed, your subscription will automatically renew for another year of uninterrupted service. But don't worry, we've got your back – you have until the last day of the last month to disable the auto-renewal option. This gives you complete control over your subscription and ensures that you only continue with us if you're completely satisfied.
        </p>
        <h3>Effortless Payment Processing with Stripe</h3>
        <p>
          To guarantee a secure and seamless payment experience, we've partnered with Stripe, a world-renowned payment processing platform. Stripe is not only exceptional for its user-friendly interface but also its robust security features. Being PCI DSS compliant, Stripe ensures that your payment information is handled with the highest level of security protocols. You can trust that your credit card details are safe and sound.
        </p>
        <h3>Automated Charges and Receipts</h3>
        <p>
          As part of our commitment to providing you with an effortless experience, we've automated the entire billing process. At the designated time, your payment will be automatically charged to the credit card on file. No need to remember due dates or worry about missed payments. You'll receive a comprehensive receipt for each transaction, giving you a clear overview of your financial interactions with us.
        </p>
        <h3>Accessible Payment History</h3>
        <p>
          We know the importance of keeping track of your financial records. That's why we've made it incredibly easy for you to monitor your payment history. On our Billing page, you'll find all charges and receipts neatly posted under Payment History. This level of transparency ensures that you're always in the loop about your financial interactions with us.
        </p>
        <p>
          <strong>Conclusion:</strong>
          At HelperFox, we believe in making every aspect of your experience with us seamless and user-friendly – and that includes payments. With our two flexible subscription options, secure payment processing through Stripe, and automated billing system, you can rest assured that your subscription is managed with care and precision. We're here to simplify your journey with us so you can focus on what truly matters.
        </p>
        </CardBody>
        </Card>
    );
}
 
export default Charges;