import { BrowserRouter, Routes, Route} from "react-router-dom";
import ActivateLayout from "./Layouts/ActivateLayout/ActivateLayout";
import AuthLayout from "./Layouts/AuthLayout/AuthLayout";
import Starter from "./views/Starter";
import Billing from "./views/ui/Billing";
import Account from "./views/ui/Account";
import Support from "./views/ui/Support";
import DashboardLayout from "./Layouts/DashboardLayout/DashboardLayout";
import ResetLayout from "./Layouts/ResetLayout/ResetLayout";
import { AuthContext } from "./context/AuthContext";
import { useContext, useEffect } from "react";
import axios from "axios";
import KnowledgeBase from "./views/ui/KnowledgeBase";
import AddListing from "./views/ui/articles/AddListing";
import ChangeAvailability from "./views/ui/articles/ChangeAvailability";
import Charges from "./views/ui/articles/Charges";
import Howdoesthiswork from "./views/ui/articles/Howdoesthiswork";
import RefundPolicy from "./views/ui/articles/RefundPolicy";
import TaxDetails from "./views/ui/articles/TaxDetails";
import TwoFA from "./components/TwoFA/TwoFA";
import API_BASE_URL from "./apiConfig"; 




function App() {
  const { dispatch, token, isLoggedIn, isAuthenticated } = useContext(AuthContext);
  // get ac token
  useEffect(() => {
    const _appSignging = localStorage.getItem("_appSignging");
    const _apprftoken = localStorage.getItem("_apprftoken");
    if (_appSignging) {
      const getToken = async () => {
        const res = await axios.post(`${API_BASE_URL}/api/auth/access`, 
        {_apprftoken: _apprftoken},   
        {withCredentials: true});
        dispatch({ type: "GET_TOKEN", payload: res.data.ac_token });
      };
      getToken();
    }
  }, [dispatch, isLoggedIn]);

  // get user data
  useEffect(() => {
    
    if (token) {
      const getUser = async () => {
        dispatch({ type: "SIGNING" });
        const res = await axios.get(`${API_BASE_URL}/api/auth/user`, {
          headers: { Authorization: token },
        });
        dispatch({ type: "GET_USER", payload: res.data });
      };
      getUser();
    }
    else console.log("NO TOKEN")
  }, [dispatch, token]);

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={isLoggedIn ? <DashboardLayout /> : <AuthLayout/>}>
                      <Route index element={<Starter />} />
                      <Route path="/starter" element={<Starter />} />
                      <Route path="/billing" element={<Billing />} />
                      <Route path="/account" element={<Account />} />
                      <Route path="/knowledgebase" element={<KnowledgeBase />} />
                      <Route path="/support" element={<Support />} />
                      <Route path="/AddListing" element={<AddListing />} />
                      <Route path="/ChangeAvailability" element={<ChangeAvailability />} />
                      <Route path="/Charges" element={<Charges />} />
                      <Route path="/Howdoesthiswork" element={<Howdoesthiswork />} />
                      <Route path="/RefundPolicy" element={<RefundPolicy />} />
                      <Route path="/TaxDetails" element={<TaxDetails />} />
            </Route>

            <Route 
              path='/auth/verify' 
              element={isAuthenticated ? <TwoFA /> : <AuthLayout/>} 
            />
            <Route
              path="/auth/reset-password/:token"
              element={<ResetLayout/>}
            />
            <Route
              path="/api/auth/activate/:activation_token"
              element={<ActivateLayout />}
            />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
