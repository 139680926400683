import { createContext, useReducer } from 'react'
import probindReducer from './ProbindReducer'

const INITIAL_STATE = {
    probinds: [],
    fetchProbinds: false
};

export const ProbindsContext = createContext(INITIAL_STATE)


export const ProbindsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(probindReducer, INITIAL_STATE)

    return (
        <ProbindsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ProbindsContext.Provider>
    )
}