const probindReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PROBINDS': //dispatched in starter.js
            return {
                probinds: action.payload
            }
        case 'CREATE_PROBIND': //dispatched in probindform.js
            return {
                fetchProbinds: true
            }

        case 'DELETE_PROBIND': //dispatched in probinddetails.js
            return {
                probinds: state.probinds.filter(probind => probind.id !== action.payload.id)
            }

        case 'UPDATE_PROBIND': //dispatched in probinddetails.js
            return {
                probinds: state.probinds.map(probind =>
                    probind.id === action.payload.id ? { ...probind, ...action.payload } : probind) //not sure this works
            }
        default:
            return state
    }
}

export default probindReducer;