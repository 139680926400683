import { useProbindsContext } from '../../hooks/useProbindsContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import axios from "axios";
import { toast } from "react-toastify";
import Modal from '../Modal/Modal';
import { useState } from "react";
// import BootstrapButton from 'react-bootstrap/Button';
import API_BASE_URL from "../../apiConfig"; 

import {
    Card,
    CardBody,
    Button,
    Label,
    Alert,
    Input,
    Form,
    FormGroup

} from "reactstrap";

//date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const ProbindDetails = ({ probind, onLocationChange }) => {
    const { dispatch } = useProbindsContext();
    const { user, token } = useAuthContext();
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    // const [data, setData] = useState('');
    const [deleteState, setDeleteState] = useState('');



    // const [data, setData] = useState(initialState);
    const [data, setData] = useState({
        businessName: probind.businessName,
        phoneNumber: probind.phoneNumber,
        firstName: probind.firstName,
        lastName: probind.lastName,
        service: probind.service,
        zipCode: probind.zipCode,
        distance: probind.distance,
        description: probind.description,
        receiveSms: probind.receiveSms,  
        price: ''  
    });

    const { businessName, phoneNumber, firstName, lastName, service, zipCode, distance, description, receiveSms, price } = data;

    const userId = user._id

    //handle change
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });

    };

    // Open delete confirmation modal

    const openDeleteConfirmationModal = (id) => {
        setDeleteConfirmation(id);
    }

    // delete a probind
    const handleDelete = async (id) => {
        if (!user) {
            return
        }

        if(deleteState.toLowerCase() !== 'delete'){
            return
        }

        setDeleteConfirmation(false);

        const res = await axios.delete(`${API_BASE_URL}/api/auth/probinds/` + probind._id, {
            headers: {
                Authorization: token
            }
        })

        if (res.status === 200) {
            dispatch({ type: 'DELETE_PROBIND', payload: id })
            // delete area covered
            await axios.delete(`${API_BASE_URL}/api/auth/ziplist/${userId}`);
            //delete availability
            await axios.delete(`${API_BASE_URL}/api/auth/availability/${userId}`);

            //reset coverage map location
            onLocationChange(null, null);

            return toast("Application has been successfully deleted.", {
                className: "toast-success",
                bodyClassName: "toast-success",
            });
        }
    }

    const handleDeleteChange = (e) => {
        setDeleteState(e.target.value)
    }

    const handleUpdate = async (id) => {
        if (!user) {
            return
        }
       
    
        const res = await axios.patch(
            '/api/auth/probinds/' + probind._id, data,
            {
                headers: { Authorization: token }
            })
        
        if (res.status === 200) {

            dispatch({ type: 'UPDATE_PROBIND', payload: id })

            return toast("Application has been successfully updated.", {
                className: "toast-success",
                bodyClassName: "toast-success",
            });
        }
    }

    return (
        <>
            <Card>
                <CardBody className="custom-card-body" style={{ height: '840px' }}>
                    <Alert color="success">
                        <h4 className="alert-heading">Your <strong>{probind.service}</strong> listing is live!</h4>
                        <p>
                            Whenever a customer in your area inquires for the services you offer, they will be directed to your business phone number you have set below.
                        </p>
                    </Alert>
                    <Form>
                        <FormGroup>

                        <Label for="contactname">Contact Name</Label>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                            <Input
                                id="firstName"
                                name="firstName"
                                placeholder={probind.firstName}
                                type="text"
                                value={firstName}
                                onChange={handleChange}
                            />
                            <Input
                                id="lastName"
                                name="lastName"
                                placeholder={probind.lastName}
                                type="text"
                                value={lastName}
                                onChange={handleChange}
                            />
                            </div>


                        

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ flex: '0 0 48%' }}>
                                <Label for="phoneNumber">Business Phone Number</Label>
                                <Input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder={probind.phoneNumber}
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={handleChange}
                                />
                            </div>

                            <FormGroup check style={{ flex: 1, paddingLeft: '70px', paddingTop: '35px', display: 'flex', alignItems: 'right' }}>
                                <Label check>
                                <Input
                                    id="receiveSms"
                                    name="receiveSms"
                                    type="checkbox"
                                    checked={data.receiveSms} 
                                    onChange={() => setData(prevData => ({ ...prevData, receiveSms: !prevData.receiveSms }))}
                                />

                                    Receive SMS messages from clients
                                </Label>
                            </FormGroup>
                        </div>

                            
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px',  paddingBottom: '15px'}}>
                                    <div style={{ flex: '0 0 48%' }}>
                                        <Label for="service">Service Offered</Label>
                                        <Input
                                            id="service"
                                            name="service"
                                            placeholder={probind.service}
                                            type="text"
                                            value={service}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={{ flex: '0 0 48%' }}>
                                        <Label for="price">Hourly Price</Label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: '5px' }}>$</span>
                                            <Input
                                                id="price"
                                                name="price"
                                                placeholder={probind.price}
                                                type="number"
                                                value={price}
                                                onChange={handleChange}
                                                style={{ width: '90px' }} 
                                            />
                                            <span style={{ marginLeft: '5px' }}> / hour</span>
                                        </div>
                                    </div>

                                </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '15px' }}>
                                <div style={{ flex: '0 0 48%', marginRight: '15px' }}>
                                    <Label for="zipCode">Zip Code</Label>
                                    <Input
                                        id="zipCode"
                                        name="zipCode"
                                        placeholder={probind.zipCode}
                                        value={zipCode}
                                        type="number"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div style={{ flex: 1 }}>
                                    <Label for="distance">Job Distance Limit</Label>
                                    <Input
                                        id="distance"
                                        name="distance"
                                        placeholder={probind.distance}
                                        value={distance}
                                        type="number"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            
                            <Label for="businessName">Business Name</Label>
                            <Input
                                id="businessName"
                                name="businessName"
                                placeholder={probind.businessName}
                                type="text"
                                value={businessName}
                                onChange={handleChange}
                            />
                            
                            <Label for="description">Description</Label>
                            <Input
                                id="description"
                                name="description"
                                placeholder={probind.description}
                                type="textarea"
                                value={description}
                                onChange={handleChange}
                                maxLength="350"
                            />
                        </FormGroup>
                        <div className="d-flex justify-content-end">
                            <Button className="btn me-2" outline color="info" onClick={handleUpdate}>
                                Update
                            </Button>
                            {/* <span className="material-symbols-outlined" onClick={handleClick}>delete</span> */}
                            <Button className="btn" outline color="danger" onClick={openDeleteConfirmationModal}>
                                Delete
                            </Button>
                        </div>
                    </Form>
                    <hr />
                    <p>Last update: {formatDistanceToNow(new Date(probind.createdAt), { addSuffix: true })}</p>
                </CardBody>
            </Card>
            <Modal
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                title="Delete business"
                footer={
                    <>
                        <Button variant="secondary" onClick={() => setDeleteConfirmation(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => handleDelete(deleteConfirmation)}>
                            Delete
                        </Button>
                    </>
                }
            >
                <p>Please enter delete</p>
                <Input
                    name="DELETE"
                    placeholder="DELETE"
                    type="text"
                    onChange={handleDeleteChange}
                    />
            </Modal>
        </>
    )
}

export default ProbindDetails