import {
    Container,
    Card,
    CardBody,

  } from "reactstrap";

const Howdoesthiswork = () => {
    return (  
        <>
        <Container>
         <Card>
        <CardBody className="custom-card-body">
        <h1>How are customers directed to my business?</h1>
        <hr/>
        <p>
        At HelperFox, we're dedicated to ensuring that customers find the right service professionals quickly and effortlessly. Our streamlined process seamlessly connects customers in need with the professionals who can meet their requirements. Here's a detailed look at how customers are directed to your business to maximize your reach and potential clientele.
      </p>
      <h3>Step 1: Customer Inquiry</h3>
      <p>
        The journey begins when a customer calls the main HelperFox phone number. During the call, the customer specifies the type of service they require and provides their location's zip code.
      </p>
      <h3>Step 2: Smart Matching</h3>
      <p>
        HelperFox's intelligent system works behind the scenes to match the customer's needs with the service professionals who have subscribed to our platform and cover the specified area. This ensures that customers are directed to professionals who are not only available but also experts in the required service.
      </p>
      <h3>Step 3: Seamless Connection</h3>
      <p>
        Once a successful match is made, the customer's call is seamlessly transferred to your phone number. This direct connection ensures that customers can reach you immediately, allowing for quick communication and effective service coordination.
      </p>
      <h3>Alternative Contact Information</h3>
      <p>
        At HelperFox, we understand that modern communication takes various forms. In addition to the phone call, we offer alternative ways for customers to access your contact information:
      </p>
      <ul>
        <li><strong>SMS:</strong> Customers can reach out via SMS to receive your contact details, enabling them to connect with you via text messaging.</li>
        <li><strong>Social Media:</strong> If customers prefer social media platforms, we provide them with your social media handles, ensuring they can engage with you through their preferred channel.</li>
        <li><strong>Email:</strong> Email inquiries allow customers to request your contact information via email, providing an additional avenue for communication.</li>
      </ul>
      <p>
        <strong>Conclusion:</strong>
        The process of directing customers to your business on HelperFox is designed for simplicity and effectiveness. By seamlessly matching customer needs with your expertise and providing various communication options, we ensure that you have every opportunity to connect with potential clients and showcase your services.
      </p>
        </CardBody>
        </Card>
        </Container>
        </>
    );
}
 
export default Howdoesthiswork;