import Forgot from "../../components/Forgot/Forgot";
import Register from "../../components/Register/Register";
import Login from "../../components/Login/Login";
import NavLogo from './helperfox-logo.png'
import "./authlayout.css";
import { useState } from "react";

const AuthLayout = () => {
  const [login, setLogin] = useState(true);
  const [register, setRegister] = useState(false);
  const [forgot, setForgot] = useState(false);

  const handleLogin = () => {
    setLogin(true);
    setRegister(false);
    setForgot(false);
  };
  const handleRegister = () => {
    setLogin(false);
    setRegister(true);
    setForgot(false);
  };
  const handleForgot = () => {
    setLogin(false);
    setRegister(false);
    setForgot(true);
  };

  return (
    <>
    <div className="authlayout_navbar">
        <div className="authlayout_logo">
            <img 
            src={NavLogo} 
            alt="logo" />
          </div>
          <div className="authlayout_link">
            <a href="https://helperfox.com">Back to Main Site</a>
        </div>
    </div>
    <div className="authInputArea">
      {/* logo */}
      
      {/* form */}
      {login && <Login />}
      {register && <Register />}
      {forgot && <Forgot />}
      {/* actions */}
      <div>
        <p
          onClick={login ? handleRegister : handleLogin}
        >
          {login ? "Sign Up Here" : "Login"}
        </p>
        <p onClick={handleForgot}>
          Forgot Password?
        </p>
      </div>
    </div>
    <div className="authlayout_footer">
      <p>Copyright © 2024 - Helperfox - All Rights Reserved.</p>
    </div>
    </>
  );
};

export default AuthLayout;
