import React, { useState } from 'react';
import {
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardGroup,
    Button,
    Row,
    Col,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from "reactstrap";
  import { Link } from 'react-router-dom';
  
  const KnowledgeBase = () => {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    }

    return (
      <div>
        <h3>Knowledge Base</h3>
        <hr />
        <Row>
          <h4 className="mb-3 mt-3">How-to Guides</h4>
          <Col md="6" lg="4">
            <Card body>
              <CardTitle tag="h5">How to add a business listing?</CardTitle>
              <CardText>
              Seamlessly add your business listing and expand your reach.
              </CardText>
              <div>
              <Link to="/AddListing" className="btn btn-light-warning">
            Read More
          </Link>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="4">
            <Card body>
              <CardTitle tag="h5">How are customers directed to my business?</CardTitle>
              <CardText>
                Find out how we connect customer needs with your expertise. 
              </CardText>
              <div>
              <Link to="/Howdoesthiswork" className="btn btn-light-warning">
            Read More
          </Link>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="4">
            <Card body>
              <CardTitle tag="h5">How can I change my availability?</CardTitle>
              <CardText>
              Control your availability and connect with customers on your time.
              </CardText>
              <div>
              <Link to="/ChangeAvailability" className="btn btn-light-warning">
            Read More
          </Link>
              </div>
            </Card>
          </Col>
        </Row>
        {/* --------------------------------------------------------------------------------*/}
        {/* Card-2*/}
        {/* --------------------------------------------------------------------------------*/}
        <Row>
          <h4 className="mb-3 mt-3">Billing Questions</h4>
          <Col md="6" lg="4">
            <Card body>
              <CardTitle tag="h5">How will I be charged?</CardTitle>
              <CardText>
                Simplifying Payments: How We Charge Our Valued Customers
              </CardText>
              <div>
              <Link to="/Charges" className="btn btn-light-warning">
            Read More
          </Link>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="4">
            <Card body>
              <CardTitle tag="h5">What is the refund policy?</CardTitle>
              <CardText>
              Cancel anytime and only pay up to the last day of the current month.
              </CardText>
              <div>
              <Link to="/RefundPolicy" className="btn btn-light-warning">
            Read More
          </Link>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="4">
            <Card body>
              <CardTitle tag="h5">How is tax applied?</CardTitle>
              <CardText>
              Discover how we apply taxes to your subscription.
              </CardText>
              <div>
              <Link to="/TaxDetails" className="btn btn-light-warning">
            Read More
          </Link>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
            <h4 className="mb-3 mt-3">Frequently Asked Questions (FAQ)</h4>
            <Card body>
            <Accordion flush open={open} toggle={toggle} className="custom-card-body">
            <AccordionItem>
              <AccordionHeader targetId="1"><strong>1. How does HelperFox benefit my business?</strong></AccordionHeader>
              <AccordionBody accordionId="1">
               With HelperFox, you tap into a streamlined process that efficiently matches you with customers 
               who need your services in specific areas. This saves you time and effort by connecting you directly 
               with potential clients, allowing you to focus on what you do best – delivering exceptional services.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="2"><strong>2. What should I do if a customer expresses dissatisfaction with the service provided?</strong></AccordionHeader>
              <AccordionBody accordionId="2">
              If a customer is dissatisfied, your first step is to listen actively and empathetically to their 
              concerns. Apologize for any inconvenience and assure them that you're committed to resolving the 
              issue. Work together to identify the problem, offer a solution, and ensure their satisfaction. NOTE: 
              HelperFox bears absolutely no responsibility over the experience with customers. 
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="3"><strong>3. How can I ensure I have a clear understanding of the customer's requirements before starting a job?</strong></AccordionHeader>
              <AccordionBody accordionId="3">
              Effective communication is key. Initiate a detailed conversation with the customer to gather all 
              necessary information about the job. Ask specific questions about their expectations, preferred 
              timeline, budget, and any unique specifications. Document everything to ensure you have a 
              comprehensive understanding.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="4"><strong>4. What if a customer provides vague or incomplete job requirements?</strong></AccordionHeader>
              <AccordionBody accordionId="4">
              If you encounter vague or incomplete requirements, it's essential to clarify and fill in the 
              gaps. Reach out to the customer and ask for additional details. Offer your expertise to help 
              them define their needs better, and ensure that you have a complete picture before moving forward.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="5"><strong>5. How can I handle unexpected challenges or changes during a project?</strong></AccordionHeader>
              <AccordionBody accordionId="5">
              Flexibility is crucial. If unexpected challenges arise or if the project requirements change, communicate 
              openly with the customer. Discuss the situation, propose solutions, and get their input. Transparency and 
              collaboration will help navigate these situations while keeping the project on track.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="6"><strong>6. What if a customer requests additional services beyond the original job description?</strong></AccordionHeader>
              <AccordionBody accordionId="6">
              When a customer requests additional services, it's an opportunity for a positive interaction. Listen to 
              their request, provide a clear breakdown of the added work and associated costs, and obtain their approval 
              before proceeding. Transparency and agreement are essential to ensure a smooth experience for both parties.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          </Card>
        </Row>
      </div>
    );
  };
  
  export default KnowledgeBase;