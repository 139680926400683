import { PayModalContext } from "../context/PayModalContext";

import { useContext } from "react";

export const usePayModalContext = () => {
    const context = useContext(PayModalContext);
    
    if (!context) {
        throw Error('usePayModalContext must be used inside a PayModalContextProvider')
    }

    return context
}
