import { Outlet } from "react-router-dom";
// import Profile from "../../components/Profile/Profile";
// import Feed from "../../components/Feed/Feed";
import Sidebar from "../../components/Sidebar/Sidebar";
// import Appbar from "../../components/Appbar/Appbar";
import Header from "../../components/Header/Header";

import { Container } from "reactstrap";
// import "./DashboardLayout.css";
// import { useState } from "react";

const DashboardLayout = () => {
  // const [sidebar, setSidebar] = useState(false);

  // const handleSidebar = () => {
  //   setSidebar(!sidebar);
  // };

  return (
    <main>
      {/********header**********/}
      {/* <div className="DashboardLayout"> */}
        {/* appbar */}
        {/* <Appbar handleSidebar={handleSidebar} /> */}
        <Header />
        <div className="pageWrapper d-lg-flex">


        {/********Sidebar**********/}
        <aside className="sidebarArea shadow" id="sidebarArea">
          <Sidebar />
        </aside>

        {/********Content Area**********/}
        <div className="contentArea">
            {/********Middle Content**********/}
            <Container className="p-4" fluid>
              <Outlet />
            </Container>
          </div>
      </div>
    </main>
  );
};

export default DashboardLayout;
