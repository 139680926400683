import { useState } from "react"
import { useProbindsContext } from "../../hooks/useProbindsContext"
import { isEmpty } from "../helper/validate";
import { useAuthContext } from '../../hooks/useAuthContext'
import Spinner from '../../components/Spinner/Spinner';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import Holidays from 'date-holidays';
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import API_BASE_URL from "../../apiConfig"; 
import serviceDescriptions from "./serviceDescriptions";

const options = [
    { value: 'plumbing', label: 'Plumbing' },
    { value: 'electrical', label: 'Electrical' },
    { value: 'roofing', label: 'Roofing' },
    { value: 'moving', label: 'Moving' },
    { value: 'hvac', label: 'HVAC' },
    { value: 'landscaping', label: 'Landscaping' },
    { value: 'painting', label: 'Painting' },
    { value: 'appliancerepair', label: 'Appliance Repair' },
    { value: 'treeservices', label: 'Tree Services' },
    { value: 'realestate', label: 'Real Estate' },
    { value: 'handyperson', label: 'Handyperson' },
    { value: 'pestcontrol', label: 'Pest Control' },
    { value: 'cleaning', label: 'Cleaning' },
    { value: 'photographer', label: 'Photographer' },
    { value: 'furnitureassembly', label: 'Furniture Assembly' },
    { value: 'gutterservices', label: 'Gutter Services' },
    { value: 'locksmiths', label: 'Locksmith' },
    { value: 'hauling', label: 'Hauling & Junk Removal' },
    { value: 'towservices', label: 'Tow Service' },
    { value: 'generalcontractors', label: 'General Contractors' },
    { value: 'interiordesigners', label: 'Interior Designers' },
    { value: 'flooringtile', label: 'Flooring & Tile' },
    { value: 'doorswindows', label: 'Doors & Windows Installation' },
    { value: 'architects', label: 'Architects' },
    { value: 'fenceinstallation', label: 'Fence Installation' },   
];

const availability = {
    Sunday: { available: false, from: '', to: '' },
    Monday: { available: true, from: '09:00:00', to: '17:00:00' },
    Tuesday: { available: true, from: '09:00:00', to: '17:00:00' },
    Wednesday: { available: true, from: '09:00:00', to: '17:00:00' },
    Thursday: { available: true, from: '09:00:00', to: '17:00:00' },
    Friday: { available: true, from: '09:00:00', to: '17:00:00' },
    Saturday: { available: false, from: '', to: '' }

}

const ProbindForm = () => {
    const { dispatch } = useProbindsContext()
    const { user, token } = useAuthContext();
    const [businessName, setBusinessname] = useState('')
    const [phoneNumber, setPhonenumber] = useState('')
    // const [contactName, setContactname] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [service, setService] = useState(null)
    const [zipCode, setZipcode] = useState('')
    const [distance, setDistance] = useState('')
    const [price, setPrice] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [receiveSms, setReceiveSms] = useState(false); 
    const [description, setDescription] = useState('');
    const [error, setError] = useState(null)
    const [year, setYear] = useState(new Date().getFullYear());
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const userId = user._id
    const handleCheckboxChange = (e) => {
        setCheckboxChecked(e.target.checked);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    }
      
    function getRandomDescription(serviceKey) {
        const descriptions = serviceDescriptions[serviceKey];
        if (!descriptions) return ""; 
        return descriptions[Math.floor(Math.random() * descriptions.length)];
    }
    
    const handleServiceChange = (selectedService) => {
        setService(selectedService);
        
        
        const randomDescription = getRandomDescription(selectedService.value);
        
        if (randomDescription) {
            setDescription(randomDescription);
        } else {
            setDescription(''); 
        }
    };  

     const handleSubmit = async (e) => {
        e.preventDefault()

        if (user.pay_status === 'paid') {
            
            if (isEmpty(phoneNumber) ||
                isEmpty(firstName) ||
                isEmpty(lastName) ||
                isEmpty(service) ||
                isEmpty(zipCode))
                return toast("Please fill in all fields.", {
                    className: "toast-failed",
                    bodyClassName: "toast-failed",
                });

            
            if (!user) {
                setError('You must be logged in')
                return
            }
            if(+distance > 35) {
                return toast("The distance must be a maximum 35 miles.", {
                    className: "toast-failed",
                    bodyClassName: "toast-failed",
                });
            }

            setSubmitLoading(true);
            
            const probind = { businessName, phoneNumber, email: user.email, firstName, lastName, service: service.value, zipCode, distance, price, receiveSms, description }
            const res = await axios.post(`${API_BASE_URL}/api/auth/probinds`, probind, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }

            });
        

            if (res.status === 200) {
           
                try {
                    const ziplistings = await axios.post(`${API_BASE_URL}/api/auth/ziplist`, { zipCode, distance, user: user._id, service: service.value, phoneNumber  });
                    
                } catch (error) {
                    console.log(error);
                }

                const gmtOffset = parseInt(res.data.timezone.GMT.replace('GMT', ''), 10);
                const adjustedAvailability = JSON.parse(JSON.stringify(availability));
                for (const day in adjustedAvailability) {
                    if (adjustedAvailability[day].available) {
                      
                        const fromHour = parseInt(adjustedAvailability[day].from.split(':')[0], 10);
                        const toHour = parseInt(adjustedAvailability[day].to.split(':')[0], 10);

               
                        const adjustedFromHour = (fromHour - gmtOffset + 24) % 24;
                        const adjustedToHour = (toHour - gmtOffset + 24) % 24;

                       
                        adjustedAvailability[day].from = `${String(adjustedFromHour).padStart(2, '0')}:00:00`;
                        adjustedAvailability[day].to = `${String(adjustedToHour).padStart(2, '0')}:00:00`;
                    }
                }
               
                try {
                    const hd = new Holidays('US');
                    const fetchedHolidays = hd.getHolidays(year).filter(holiday => holiday.type === 'public' || holiday.type === 'bank');
                    const data = {
                        userId: user._id,
                        workingDays: adjustedAvailability,
                        workingHours: 'Standard Hours (9am - 5pm)',
                        holidays: fetchedHolidays
                    };

                    await axios.post(`${API_BASE_URL}/api/auth/availability`, data);
                } catch (error) {
                    console.error('Error saving availability:', error);
                }


                if (selectedFile) {  
                    const formData = new FormData();
                    formData.append('avatar', selectedFile);
                    try {
                        const response = await axios.post(`${API_BASE_URL}/api/auth/uploadPhoto`, formData, {
                            headers: {
                                "content-type": "multipart/form-data",
                                Authorization: token,
                            },
                        });
                        console.log("Avatar Response ", response);
            
                        if (response.status === 200) {
                            
                            console.log("user.avatar ", user.avatar);
                            try {
                                await axios.patch(
                                    `${API_BASE_URL}/api/auth/updateurl/${userId}`,
                                    { avatar: response.data.url },
                                    { headers: { Authorization: token } }
                                );                     
                            } catch (error) {
                                console.log("Error uploading file:", error);
                            }
                        }
                    } catch (error) {
                        const errorMessage = error.response ? error.response.data.msg : 'An error occurred';
                        toast(errorMessage, {
                            className: "toast-failed",
                            bodyClassName: "toast-failed",
                        });
                        setSubmitLoading(false);
                        return;  
                    }
                } else {
                    
                    try {
                        const defaultAvatar = await axios.patch(
                            `${API_BASE_URL}/api/auth/updateurl/${userId}`,
                            { avatar: user.avatar },
                            { headers: { Authorization: token } }
                        );
                        console.log("Default Avatar Response", defaultAvatar);
                    } catch (error) {
                        const errorMessage = error.response ? error.response.data.msg : 'An error occurred';
                        console.log("Error updating default avatar:", errorMessage);
                    }
                }
                setSubmitLoading(false);
                dispatch({ type: 'CREATE_PROBIND' })
                setBusinessname('')
                setPhonenumber('')
                setReceiveSms(false)
                setFirstName('')
                setLastName('')
                setService('')
                setPrice('')
                setZipcode('')
                setDistance('')
                setDescription('')
                setError(null)
                setEmptyFields([])
                
                return toast("New Application Added", {
                    className: "toast-success",
                    bodyClassName: "toast-success",
                });
            }

            if (res.status !== 200) {
                setError(res.err)
               
                return toast(res.err.data.msg, {
                    className: "toast-failed",
                    bodyClassName: "toast-failed",
                });
            }

        } else {
        
            return toast("Please upgrade your account before creating a new business listing.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
            });
        }

    };

    return (
        <>
            <ToastContainer />
            <Card>
                <CardBody className="custom-card-body">
                    <Form className="create" onSubmit={handleSubmit}>
                        <h3>Add a Service Listing</h3>
                        
                        <FormGroup>
                            <Label>Contact Name</Label>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <Input
                                    type="text"
                                    placeholder="First Name"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                />
                                <Input
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                />
                            </div>
                        </FormGroup>
                        
                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ flex: '0 0 48%', marginRight: '10px' }}>
                                <Label>Phone Number</Label>
                                <PhoneInput
                                    style={{ width: '100%' }} 
                                    value={phoneNumber}
                                    onChange={(value) => setPhonenumber(value)}
                                    defaultCountry="US"
                                />
                            </div>
                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={(e) => setReceiveSms(e.target.checked)} 
                                    value={receiveSms}  
                                />
                                {' '} Opt-in to receive SMS on this phone number from customers?
                                </Label>
                            </div>
                            
                        </FormGroup>

                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flex: '0 0 48%' }}>
                                <Label for="services">Service</Label>
                                <Select
                                    isSearchable={true}
                                    placeholder="Start typing"
                                    id="services"
                                    options={options}
                                    value={service}
                                    onChange={handleServiceChange}
                                />
                            </div>
                            <div style={{ flex: '0 0 48%' }}>
                                <Label for="price">Price (If Applicable) </Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Input
                                        type="number"
                                        id="price"
                                        placeholder=""
                                        value={price}
                                        onChange={(e) => {
                                            const value = parseFloat(e.target.value);
                                            if (value >= 1.00 && value <= 999.99) {
                                                setPrice(value);
                                            }
                                        }}
                                        min="1.00"
                                        max="999.99"
                                        step="0.01" 
                                        style={{ width: '90px' }}
                                    />

                                    <span style={{ marginLeft: '5px' }}> / hour</span>
                                </div>
                            </div>

                        </FormGroup>

                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ flex: '0 0 48%', marginRight: '10px' }}>
                                <Label for="photo">Add a Photo</Label>
                                <Input type="file" name="avatar" id="photo" onChange={handleFileInputChange} />
                            </div>
                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={handleCheckboxChange}
                                />
                                {' '} Business Name
                                </Label>
                                {isCheckboxChecked && (
                                <Input
                                    type="text"
                                    id="businessName"
                                    onChange={(e) => setBusinessname(e.target.value)}
                                    value={businessName}
                                />
                                )}
                            </div>
                        </FormGroup>    
                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: '0 0 48%' }}>
                            <Label>Zip Code</Label>
                            <Input
                                type="number"
                                onChange={(e) => setZipcode(e.target.value)}
                                value={zipCode}
                            />
                        </div>
                        <div style={{ flex: '0 0 48%' }}>
                            <Label>Job Distance Limit</Label>
                            <Input
                                type="number"
                                placeholder="Up to 35 miles"
                                onChange={(e) => setDistance(e.target.value)}
                                value={distance}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                type="textarea"
                                maxLength="350"
                                onChange={(e) => setDescription(e.target.value)}  
                                value={description}  
                            />
                        </FormGroup>
                        <Button>{submitLoading ? <Spinner color="#fff" /> : 'Submit'}</Button>
                        {error && <div className="error">{error}</div>}
                    </Form>
                </CardBody>
            </Card>
        </>
    );
}

export default ProbindForm;