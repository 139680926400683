import Reset from "../../components/Reset/Reset";
import { useNavigate } from "react-router";
import "./resetlayout.css";

const ResetLayout = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="authInputArea">
      {/* logo */}

      {/* form */}
      <Reset />
      {/* actions */}
      <p onClick={handleClick}>
        Back to Login
      </p>
    </div>
  );
};

export default ResetLayout;
