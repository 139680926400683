import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import {
  Alert,
  Card,
  Col,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Table
} from "reactstrap";
import { Link } from 'react-router-dom';
import API_BASE_URL from "../../apiConfig"; 




const Support = () => {
  const { user } = useAuthContext();
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const userEmail = user.email;

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [ticketNumber, setTicketNumber] = useState(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (ticketNumber) {
      setSubject(
        `CLIENTAIN USER TICKET: ${userEmail} ${subject} - Ticket Number: ${ticketNumber}`
      );
    }
  }, [ticketNumber]);

  const generateTicketNumber = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const formattedDate = `${month < 10 ? "0" + month : month}${day < 10 ? "0" + day : day}${year}`;
  
    const min = 1000;
    const max = 9999;
    const randomTicketNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    const ticketNumberWithDate = formattedDate + randomTicketNumber;
    setTicketNumber(ticketNumberWithDate); // Set the ticket number in the state
    return ticketNumberWithDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ticketNumber = generateTicketNumber(); 
  
   
    const emailContent = {
      to: "contact@helperfox.com",
      from: userEmail,
      subject: `CLIENTAIN USER TICKET: ${userEmail} - Ticket Number: ${ticketNumber}`,
      description: userEmail + " : " + description,
    };
  
    
    try {
      
      const response = await axios.post(`${API_BASE_URL}/api/auth/send-request`, emailContent);
      
      if (response.status === 200) {
        setSubject("");
        setDescription("");
        setSelectedFile(null);
        toggleModal();
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  

  return (
    <>
     
    <Row>
    <Col md=""> 
       <Alert color="success">
        <h5 className="alert-heading">Before opening a ticket, consider checking out the <Link to="/knowledgebase">Knowledge Base</Link> articles.</h5>
    </Alert>     
      <Card style={{ height: '550px' }}>
        <CardBody className="custom-card-body">
          <Form className="account" onSubmit={handleSubmit}>
            <FormGroup>
              <h3>Create Ticket</h3>
              <FormText>
                If you need more information, please check out our Knowledge Base.
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label>Subject</Label>
              <Input
                type="subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </FormGroup>
            <FormGroup>
              <Label>Problem</Label>
              <Input id="exampleSelect" name="select" type="select">
                <option>Please choose one</option>
                <option>Technical</option>
                <option>Billing</option>
                <option>Account Related</option>
                <option>Something Else</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Text Area</Label>
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </FormGroup>
            <Col xs="6">
              <FormGroup>
                <Label for="exampleFile">File</Label>
                <Input
                  id="exampleFile"
                  name="file"
                  type="file"
                  onChange={handleFileInputChange}
                />
              </FormGroup>
            </Col>
            <div className="d-flex justify-content-end">
              <Button type="submit">Submit</Button>
            </div>
            
            {/* {error && <div className="error">{error}</div>} */}
          </Form>







          {/* Modal */}
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Ticket Submitted</ModalHeader>
            <ModalBody>
              The ticket has been submitted. Ticket number is {ticketNumber}.
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Col> 


    <Col md="4">
          <Card style={{ height: '215px' }}>
            <CardBody className="custom-card-body">
              <h4>Support Contact Info</h4>
              <hr />
              <div>
                <strong>PHONE NUMBER </strong>: 1 866 211 2779 Press *
              </div>
              <div>
                <strong>EMAIL </strong>: contact@helperfox.com 
              </div> 
             <br/>
              <div>
                <strong>SUPPORT HOURS </strong>: <br/>Monday - Friday 9:00am - 6:00pm EST
              </div>
            </CardBody>
          </Card>

          <Card style={{ height: '383px' }}>
            <CardBody className="custom-card-body">
              {/* <h4>Ticket History</h4>
              <hr /> */}
              <Table>
                <thead>
                  <tr>
                    <th>Ticket Number</th>
                    <th>Date Opened</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Table rows */}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        
        
    </Row>
    </>
  );
};

export default Support;

