import { useAuthContext } from '../../hooks/useAuthContext';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card, Button, CardBody, Table } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import API_BASE_URL from "../../apiConfig"; 
import dotenv from 'dotenv';
dotenv.config();


const Method = () => {
  const [cardData, setCardData] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [stripe, setStripe] = useState(null);
  const { user } = useAuthContext();

  const stripeCustomerId = user.stripeCustomerId;

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/auth/payinfo?stripeCustomerId=${stripeCustomerId}`);
        const card = response.data[0].card;
        const id = response.data[0].id;
        setCardData(card);
        setPaymentId(id);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCardData();
  }, [stripeCustomerId]);

  useEffect(() => {
    const loadStripeInstance = async () => {
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
    };

    loadStripeInstance();
  }, []);

  const handleUpdatePaymentMethod = async () => {
    try {
      const customerId = user.stripeCustomerId;

      // Make the API request to create a session for payment method update
      const response = await axios.post(`${API_BASE_URL}/api/auth/update-payment-method`, {
        customerId,
      });

      const sessionId = response.data.sessionId;

      // Redirect the user to the Stripe Checkout page
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Error redirecting to checkout:', error.message);
      }
    } catch (error) {
      console.error('Error creating session:', error.message);
    }
  };

  return (
    <Card style={{ height: '300px' }}>
      <CardBody className="custom-card-body">
        <h4>Payment Method</h4>
        <hr />

        {!cardData ? (
          <div>Loading...</div>
        ) : (
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Expiry date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {cardData.brand} **** **** **** {cardData.last4}
                  </td>
                  <td>
                    {cardData.exp_month} / {cardData.exp_year}
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <div className="d-flex justify-content-end">
              <Button className="btn" outline color="primary" onClick={handleUpdatePaymentMethod}>
                Update
              </Button>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Method;
