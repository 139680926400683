import React, { createContext, useState } from "react";

export const PayModalContext = createContext();

export const PayModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <PayModalContext.Provider value={{ modalOpen, toggleModal }}>
      {children}
    </PayModalContext.Provider>
  );
};


