import {useAuthContext} from '../../hooks/useAuthContext'
import axios from "axios";
import { useEffect, useState } from "react";
import {
    Card,
    CardBody,
  } from "reactstrap";
  import PayModal from "../Modals/PayModal";
  import API_BASE_URL from "../../apiConfig"; 


const Subscription = () => {
  const [subData, setSubData] = useState(null);
  const {user} = useAuthContext()

  const subscriptionId = user.subscriptionId;

  useEffect(() => {
    const fetchSubData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/auth/subscriptions?subscriptionId=${subscriptionId}`);
        setSubData(response);
        
      } catch (error) {
        console.log(error);
      }
    };

    fetchSubData();
  }, []);


  //Cancel subscription(recurring charge)
  const handleCancelSubscription = async () => {
    try {
      // Make the API request to cancel the subscription
      const response = await axios.delete(`${API_BASE_URL}/api/auth/cancel-subscription/${subscriptionId}`, {
      });

      
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error canceling subscription:', error.message);
    }
  };



  return ( 
   <Card style={{ height: '300px' }}>      
      <CardBody className="custom-card-body">
        <h4>Subscription Details</h4>
        <hr /> 
        {!subData ? ( 
          <div>Loading...</div>
        ) : (      
          <div>
              {/* If your application offers subscription-based services, allow customers to view and manage their subscription details, such as the plan they are subscribed to, the billing frequency, and the next payment date */}
              <div>
                <strong>PLAN STATUS : </strong>{subData.data.status} {subData.data.status === 'active' ? (
                        <span className="text-danger">&#x2705;</span>
                      ) : (
                        <span className="text-danger">&#x274C;</span>
                      )}
              </div>
              <div>
                <strong>AUTO RECHARGE : </strong>{subData.data.status === 'active' ? (
                  <span>
                    enabled <span className="text-success" style={{ marginRight: '20px' }}>&#x2705;</span> <a href="#" onClick={handleCancelSubscription}>Disable auto recharge</a>
                  </span> 
                ) : (
                  <span>
                    disabled <span className="text-danger" style={{ marginRight: '20px' }}>&#x274C;</span>
                  </span>
                )}
              </div>

              <br/>
              <div>
                <strong>LAST COMPLETED PAYMENT </strong>: {new Date(subData.data.current_period_start * 1000).toLocaleString()}
              </div>

              <div>
                <strong>AMOUNT </strong>: {((subData.data.plan.amount) / 100).toFixed(2)} {(subData.data.plan.currency).toUpperCase()} / {subData.data.plan.interval}
              </div>
              <br/>
              <div> 
              {subData.data.status === 'active' ? (
                  <strong>NEXT SCHEDULED PAYMENT</strong>
                ) : (
                  <strong>YOUR SUBSCRIPTION END DATE</strong>
                )} : {new Date(subData.data.current_period_end * 1000).toLocaleString()}

                  {user.pay_status !== 'paid' && user.sub_status !== null && <PayModal />} 
              </div>
               
             </div>
            )}
      
        
      </CardBody>
     </Card>
     );
}
 
export default Subscription;