import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

const TaxDetails = () => {
    return (  
      <Card className="custom-card-body">
        <CardBody>    
          <h2>How Tax is Applied?</h2>
          <hr />
          <p>
            At HelperFox, we are committed to providing clarity and transparency in every aspect of your experience with us, including how taxes are applied to your subscription. As a Massachusetts-based company serving customers across the USA, we adhere to tax regulations to ensure a fair and consistent approach to taxation. This article outlines how tax is applied to your subscription payments.
          </p>
          <h3>Tax Calculation and Application</h3>
          <p>
            The application of taxes depends on your location. If you are located within the state of Massachusetts, the state sales tax will be applied to your subscription payments. For customers located outside of Massachusetts, no state sales tax will be applied.
          </p>
          <h3>Dynamic Tax Rates</h3>
          <p>
            It's important to note that tax rates may vary depending on your location. We leverage sophisticated technology to dynamically calculate the applicable tax based on your billing address. This ensures that you are charged the correct tax amount in compliance with local tax regulations.
          </p>
          <h3>Transparent Billing</h3>
          <p>
            Your billing statement will clearly indicate the tax amount, if applicable, along with your subscription charge. This level of transparency ensures that you have a comprehensive understanding of the charges associated with your subscription.
          </p>
          <h3>Tax Exemptions</h3>
          <p>
            If you are eligible for tax exemptions due to your tax-exempt status, please contact our customer support team. We will guide you through the necessary steps to provide the required documentation and ensure that your tax exemptions are applied appropriately.
          </p>
          <h3>Conclusion</h3>
          <p>
            At HelperFox, our commitment to transparency extends to how taxes are applied to your subscription. We strive to provide you with accurate and clear information about your charges, ensuring that you have a seamless experience while remaining compliant with tax regulations. If you have any questions or require further assistance regarding taxation, please don't hesitate to reach out to our dedicated customer support team.
          </p>
      </CardBody>
    </Card>
    );
}
 
export default TaxDetails;